
import Event from './Event';
import Events from './Events';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: Event,
      list: Events,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'Events',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/events`,
        getLink:    `https://${serverConfig.blldomain}/api/events/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/events`,
      },
    };
  }

}
