// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// Material Design
import List, {ListItem, ListItemText} from '@material/react-list';
import Button from '@material/react-button';
import Checkbox from '@material/react-checkbox';

// Hepheastus
import DataContainer from '../../modules/DataContainer';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

class Permission extends Component {

  constructor(props){
    super(props);
    this.permissions = ['https://www.googleapis.com/auth/calendar.events'];
    this.dataContainer = props.dataContainer;
    this.state = {
      scope:{
        'https://www.googleapis.com/auth/calendar.events': 1,
      },
      gotToken: false,
    };
    this.queryObjects = queryString.parse(window.location.search);
    this.window = null;
    console.log(this.queryObjects);
    if(this.queryObjects.code){
      this.requestToken(this.queryObjects.code, this.queryObjects.state);
    }
  }

  requestToken(code, state){
    console.log('Request Token');
    var body = JSON.stringify({
      'code':code,
      'serviceUUID': serverConfig.oauth.google.serviceUUID, // Google
      'user':{
        'id': serverConfig.oauth.userUUID,
      },
      'state':state,
    });

    fetch(`https://${serverConfig.blldomain}/auth/code`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': this.dataContainer.sessionToken,
      },
      body: body,
    }).then(response => response.json())
      .then(response => {
        if(response.message === 'Accepted'){
          window.postMessage('Accepted',`https://${serverConfig.domain}/permissions`);
        }
      });
  }

  getScope(){
    var scope = '';
    for(var i in this.state.scope){
      if(this.state.scope[i]===1){
        scope += i + ' ';
      }
    }
    return scope;
  }

  changeData(name, value){
    var scope = this.state.scope;
    scope[name] = value;
    this.setState({
      scope: scope,
      gotToken: false,
    });
  }

  getPermission() {
    var url = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&'+
    'client_id=387010682553-od6rnlbj1k4rhbogetsj7shb8rvgimuq.apps.googleusercontent.com&state=aaa&scope='+this.getScope()+'&'+
    `access_type=offline&prompt=consent&redirect_uri=${serverConfig.oauth.google.redirectURI}`;
    this.window = window.open(url, '', 'width=400,height=400');
    this.window.addEventListener('message', e => {
      if (e.origin === `https://${serverConfig.domain}` && e.source === this.window){
        if(e.data === 'Accepted'){
          this.setState({gotToken:true});
          this.window.close();
        }
      }
    });
  }

  render(){
    if(window.location.pathname === '/google_permissions/code'){
      return (<p>...Wait a second...</p>);
    }

    return (
      <div>
        <p>Ask permission:</p>
        <List dense checkboxList selectedIndex={[]}>
          {this.permissions.map(permission =>(
            <ListItem key={permission}>
              <Checkbox
                nativeControlId={'checkbox-'+permission}
                checked={this.state.scope[permission]===1}
                onChange={(e) => {
                  this.changeData(permission, e.target.checked?1:0);
                }}
                onClick={(e)=>{
                  e.stopPropagation();
                }}
              />
              <ListItemText
                primaryText={permission}
              />
            </ListItem>
          ))}
        </List>
        <p>Scope: {this.getScope()}</p>
        <Button
          onClick={() => {this.getPermission();}}
          raised icon={<Checkbox checked={this.state.gotToken} disabled/>}
          disabled={this.state.gotToken}
        >
          Ask permission
        </Button>

      </div>
    );
  }
}

Permission.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};


export default Permission;
