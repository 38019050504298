// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material Design
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

// Other Components
import EventTimerCard from '../EventTimerCard';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import DataContainer from '../../modules/DataContainer';


export default class EventTimerCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      list: this.dataContainer.getAll('EventTimers'),
    };
  }

  openAddEventTimerDialog(){
    // var note = new Note();
    // note.parrentUUID = null;
    // var notedialog = <NoteEditDialog note={note} inbox={false} dataContainer={this.dataContainer}/>;
    // ReactDOM.render(notedialog, Dialogbox.getRef().current);
  }

  componentDidMount(){
    Snackbarbox.setFAB(true);
  }

  componentWillUnmount(){
    Snackbarbox.setFAB(false);
  }

  render(){
    var filteredList = this.state.list.getAll('endDate',null);
    return (
      <div>
        <p>Event Timers</p>
        {filteredList.items.length===0?(
          <p>No Timers to show</p>
        ):(
          <div className='cardCollection'>
            {filteredList.items.map(eventTimer =>(
              <EventTimerCard
                key={eventTimer.eventTimerUUID}
                eventTimer={eventTimer}
                dataContainer={this.dataContainer}
              />
            ))}
          </div>
        )}

        <Fab
          className='app-fab--absolute'
          icon={<MaterialIcon icon="add"/>}
          onClick={() => this.openAddEventTimerDialog()}
        />
      </div>
    );
  }
}

EventTimerCards.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
