// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

// Other Components
import NoteCard from '../NoteCard';
import TaskCard from '../TaskCard';
import ProjectCard from '../ProjectCard';
import Showdown from 'showdown';
import TaskEditDialog from '../TaskEditDialog';
import NoteEditDialog from '../NoteEditDialog';
import ProjectEditDialog from '../ProjectEditDialog';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Task} from '../../modules/Tasks';
import {Note} from '../../modules/Notes';
import {Project} from '../../modules/Projects';
import DataContainer from '../../modules/DataContainer';


export default class InboxCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      list: this.dataContainer.getAll('Inbox'),
      openFabCollection: false,
    };
    // SECURITY: Check settings
    this.markdownConverter = new Showdown.Converter({
      noHeaderId: true,
      headerLevelStart: 2,
      simplifiedAutoLink: true,
      strikethrough: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true,
      backslashEscapesHTMLTags: true,
    });
  }

  openAddTaskDialog(){
    var task = new Task();
    task.parrentUUID = null;
    var taskdialog = <TaskEditDialog task={task} inbox={true} dataContainer={this.dataContainer}/>;
    ReactDOM.render(taskdialog, Dialogbox.getRef().current);
  }

  openAddNoteDialog(){
    var note = new Note();
    note.parrentUUID = null;
    var notedialog = <NoteEditDialog note={note} inbox={true} dataContainer={this.dataContainer}/>;
    ReactDOM.render(notedialog, Dialogbox.getRef().current);
  }

  openAddProjectDialog(){
    var project = new Project();
    project.parrentUUID = null;
    var projectdialog = <ProjectEditDialog project={project} inbox={true} dataContainer={this.dataContainer}/>;
    ReactDOM.render(projectdialog, Dialogbox.getRef().current);
  }

  componentDidMount(){
    Snackbarbox.setFAB(true);
  }

  componentWillUnmount(){
    Snackbarbox.setFAB(false);
  }

  addIncludedElements(){
    this.state.list.items.map(element =>{
      if(!element.includedElement && element.nodeUUID){
        var node = this.dataContainer.get('Nodes','nodeUUID',element.nodeUUID);
        if(node){
          element.includedElement = this.dataContainer.get(node.nodeType,'nodeUUID',element.nodeUUID);
        }
      }
      return element;
    });
  }

  render(){
    this.addIncludedElements();
    return (
      <div>
        <p>Inbox</p>
        {this.state.list.items.length===0?(
          <p>Inbox is empty! :)</p>
        ):(
          <div className='cardCollection'>
            {this.state.list.items.map(element =>{
              if(element.includedElement instanceof Task){
                return (
                  <TaskCard
                    key={element.nodeUUID}
                    task={element.includedElement}
                    dataContainer={this.dataContainer}
                  />
                );
              }else if(element.includedElement instanceof Note){
                return (
                  <NoteCard
                    key={element.nodeUUID}
                    note={element.includedElement}
                    dataContainer={this.dataContainer}
                    markdownConverter={this.markdownConverter}
                  />
                );
              }else if(element.includedElement instanceof Project){
                return (
                  <ProjectCard
                    key={element.nodeUUID}
                    project={element.includedElement}
                    dataContainer={this.dataContainer}
                  />
                );
              }else{
                return (<p key={element.nodeUUID}>Element not Implemented: {element.nodeUUID}</p>);
              }
            })}
          </div>
        )}

        <div className='app-fab-collection--absolute'>
          <div className={'fab-collection ' + (this.state.openFabCollection?'':'fab-collection--inactive')}>
            <Fab
              mini
              icon={<MaterialIcon icon="playlist_add_check"/>}
              onClick={() => this.openAddTaskDialog()}
            />
            <Fab
              mini
              icon={<MaterialIcon icon="note"/>}
              onClick={() => this.openAddNoteDialog()}
            />
            <Fab
              mini
              icon={<MaterialIcon icon="insert_drive_file"/>}
              onClick={() => this.openAddProjectDialog()}
            />
          </div>
          <Fab
            icon={<MaterialIcon icon={this.state.openFabCollection?'close':'add'}/>}
            onClick={() => this.setState({openFabCollection: !this.state.openFabCollection})}
          />
        </div>
      </div>
    );
  }
}

InboxCards.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
