// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import List, {ListItem, ListItemText,ListItemGraphic,ListItemMeta} from '@material/react-list';
import Checkbox from '@material/react-checkbox';
import MaterialIcon from '@material/react-material-icon';
import MenuSurface, {Corner} from '@material/react-menu-surface';

// Other Components
import TaskEditDialog from '../TaskEditDialog';
import TaskDeleteDialog from '../TaskDeleteDialog';
import Dialogbox from '../Dialogbox';

// Hepheastus
import {Task} from '../../modules/Tasks';
import {EventTimer} from '../../modules/EventTimers';
import {Event} from '../../modules/Events';
import DataContainer from '../../modules/DataContainer';

class TaskListItem extends Component {

  state = {
    task: null,
    menu: false,
    anchorElement: null,
  }

  constructor(props){
    super(props);
    this.state.task = props.task;
    this.dataContainer = props.dataContainer;
    this.setParrentUUID = props.setParrentUUID;
  }

  setAnchorElement = (element) => {
    if (this.state.anchorElement) {
      return;
    }
    this.setState({anchorElement: element});
  }

  changeTaskData(name, value){
    var task = this.state.task;
    task[name] = value;
    task.lastChangedDate = new Date().toISOString();
    this.setState({
      task: task,
    });
    this.saveTask();
  }

  saveTask(){
    if(this.state.task.links.self){
      this.state.task.update(this.dataContainer.sessionToken, ['status','lastChangedDate']);
    }
    this.dataContainer.changeFunc();
  }

  static openEditTaskDialog(task,dataContainer){
    var taskdialog = <TaskEditDialog task={task} dataContainer={dataContainer}/>;
    ReactDOM.render(taskdialog, Dialogbox.getRef().current);
  }

  static openDeleteTaskDialog(task,dataContainer){
    var taskdialog = <TaskDeleteDialog task={task} dataContainer={dataContainer}/>;
    ReactDOM.render(taskdialog, Dialogbox.getRef().current);
  }

  async startTimer(){
    var timer = new EventTimer();
    await timer.startTimer(this.dataContainer.sessionToken, this.state.task.nodeUUID);
    this.dataContainer.add('EventTimers',timer);
    if(timer.includedElement && timer.includedElement instanceof Event){
      this.dataContainer.add('Events',timer.includedElement);
    }
  }

  render(){
    var dueDate = '';
    if(this.state.task.dueDate){
      dueDate = new Date(this.state.task.dueDate);
      dueDate = this.dataContainer.formatDate(dueDate);
    }

    var tasklist = this.dataContainer.getAll('Tasks').getAll('parrentUUID', this.state.task.nodeUUID);
    var completedSubtasks = 0;
    var totalSubtasks = tasklist.items.length;
    for(var i in tasklist.items){
      if(tasklist.items[i].status === 1){
        completedSubtasks++;
      }
    }

    return (
      <ListItem key={this.state.task.nodeUUID}
        onClick={() => {
          console.log('listItem');
        }}
        onDoubleClick={() => {
          this.setParrentUUID(this.state.task.nodeUUID);
        }}
      >
        <Checkbox
          nativeControlId={'checkbox-'+this.state.task.nodeUUID}
          checked={this.state.task.status===1}
          onChange={(e) => {
            // console.log('checkbox');
            this.changeTaskData('status', e.target.checked?1:0);
          }}
          onClick={(e)=>{
            e.stopPropagation();
          }}
        />
        <ListItemText
          primaryText={this.state.task.name}
          secondaryText={
            <div>
              <span style={{color: 'var($mdc-theme-text-primary-on-surface, rgba(0, 0, 0, 0.87))'}}>
                {completedSubtasks}/{totalSubtasks}
              </span>
              &ensp;–&ensp;{dueDate}
            </div>
          }
        />

        <ListItemMeta
          meta={
            <div
              onClick={(e) => {e.stopPropagation();this.setState({menu: !this.state.menu});}}
              className='mdc-menu-surface--anchor'
              ref={this.setAnchorElement}
            >
              <MaterialIcon
                icon='menu'
              />
            </div>}
        />
        <MenuSurface
          open={this.state.menu}
          anchorCorner={Corner.BOTTOM_LEFT}
          onClose={() => this.setState({menu: false})}
          anchorElement={this.state.anchorElement}
        >
          <List>
            <ListItem
              onClick={(e) => {
                e.stopPropagation();
                TaskListItem.openEditTaskDialog(this.state.task,this.dataContainer);
              }}>
              <ListItemGraphic
                graphic={<MaterialIcon icon='create'/>}
              />
              <ListItemText primaryText='Edit'/>
            </ListItem>
            <ListItem
              onClick={(e)=>{
                e.stopPropagation();
                TaskListItem.openDeleteTaskDialog(this.state.task,this.dataContainer);
              }}>
              <ListItemGraphic
                graphic={<MaterialIcon icon='delete'/>}
              />
              <ListItemText primaryText='Delete'/>
            </ListItem>
            <ListItem
              onClick={(e)=>{
                e.stopPropagation();
                this.startTimer();
                this.setState({menu: false});
              }}>
              <ListItemGraphic
                graphic={<MaterialIcon icon='play_arrow'/>}
              />
              <ListItemText primaryText='Start Timer'/>
            </ListItem>
          </List>
        </MenuSurface>
      </ListItem>
    );
  }
}

TaskListItem.propTypes = {
  task: PropTypes.instanceOf(Task),
  dataContainer: PropTypes.instanceOf(DataContainer),
  setParrentUUID: PropTypes.func,
};


export default TaskListItem;
