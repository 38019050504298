// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Material Design
import Card from '@material/react-card';

// Other Components
import TaskCard from '../TaskCard';
import ProjectCard from '../ProjectCard';

// Hepheastus
import DataContainer from '../../modules/DataContainer';


export default class DElementsDueSoon extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    var startRange = new Date();
    startRange.setDate(startRange.getDate() - 1);
    this.startRange = startRange.toISOString();// Now min 1 day
    var endRange = new Date();
    endRange.setDate(endRange.getDate() + 14);
    this.endRange = endRange.toISOString();// Now plus 2 weeks
    this.state = {
      projects: this.dataContainer.getAll('Projects','dueDate',[this.startRange, this.endRange]).sort('dueDate').limit(3),
      tasks: this.dataContainer.getAll('Tasks','dueDate',[this.startRange, this.endRange]).sort('dueDate').limit(3),
    };
    this.dataChangedListener = this.dataChange.bind(this);
  }

  dataChange(e){
    if(['Tasks','Projects'].includes(e.detail.datatype)){
      this.setState({
        projects: this.dataContainer.getAll('Projects','dueDate',[this.startRange, this.endRange]).sort('dueDate').limit(3),
        tasks: this.dataContainer.getAll('Tasks','dueDate',[this.startRange, this.endRange]).sort('dueDate').limit(3),
      });
    }
  }

  componentDidMount(){
    window.addEventListener('dataContainerChange', this.dataChangedListener);
  }

  componentWillUnmount() {
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
  }

  openNode(parrentUUID){
    if(parrentUUID !== null){
      this.setState({redirect: '/nodes/'+parrentUUID});
    }else{
      this.setState({redirect: '/nodes'});
    }
  }

  render(){
    if (this.state.redirect) {
      this.setState({redirect: null});
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Card>
        <h3>Due Items</h3>
        {this.state.projects.items.length===0&&this.state.tasks.items.length===0?(
          <p>No items</p>
        ):(
          <div className='cardCollection'>
            {this.state.projects.items.map(element =>{
              return (
                <ProjectCard
                  key={element.nodeUUID}
                  project={element}
                  dataContainer={this.dataContainer}
                  setParrentUUID={this.openNode.bind(this)}
                />
              );
            })}
            {this.state.tasks.items.map(element =>{
              return (
                <TaskCard
                  key={element.nodeUUID}
                  task={element}
                  dataContainer={this.dataContainer}
                  setParrentUUID={this.openNode.bind(this)}
                />
              );
            })}
          </div>
        )}
      </Card>
    );
  }
}

DElementsDueSoon.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
