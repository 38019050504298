// React
import React, { Component } from 'react';
//import PropTypes from 'prop-types';

// Material Design

// Hepheastus


export default class Dialogbox extends Component {

  static ref = React.createRef();

  // constructor(props){
  //   super(props);
  // }

  static getRef(){
    return this.ref;
  }

  render(){
    return (
      <div id='dialogbox' ref={Dialogbox.getRef()}>
      </div>
    );
  }
}

Dialogbox.propTypes = {

};
