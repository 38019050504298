import {API_Element_Object} from '../API_Object';
import Joi from '@hapi/joi';

import ModuleProperties from './ModuleProperties';

export default class Event extends API_Element_Object {

  constructor(data = null){
    super(data);
    this.type = 'event';
    this.keyPropertie = 'eventUUID';
    this.validator = this.constructor.validator();
    this.insertProperties = ['eventUUID','summary','description','creationDate',
      'lastChangedDate','colorID','relatedNodeUUID','startDate','endDate','googleEventID'];
    this.updateProperties = ['summary','description','lastChangedDate','colorID',
      'relatedNodeUUID','startDate','endDate','googleEventID'];
    this.storeProperties = ['type','eventUUID','summary','description','creationDate',
      'lastChangedDate','colorID','relatedNodeUUID','startDate','endDate',
      'googleEventID','links'];
    if(data != null){
      this.parseData(data);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
    this.eventUUID = this.key;
    this.summary = '';
    this.description = '';
    this.creationDate = new Date().toISOString();
    this.lastChangedDate = new Date().toISOString();
    this.colorID = '0';
    this.relatedNodeUUID = null;
    this.startDate = new Date().toISOString();
    this.endDate = new Date().toISOString();
    this.googleEventID = null;
  }

  static validator(){
    return {
      type: Joi.string().valid('event').required(),
      eventUUID: Joi.string().guid({version:'uuidv4'}).required(),
      summary: Joi.string().min(1).max(128).required(),
      description: Joi.string().min(0).max(1024).allow('').optional(),
      creationDate: Joi.date().iso().required(),
      lastChangedDate: Joi.date().iso().required(),
      colorID: Joi.string().min(1).max(6).required(),
      relatedNodeUUID: Joi.string().guid({version:'uuidv4'}).required(),
      startDate: Joi.date().iso().required(),
      endDate: Joi.date().iso().required(),
      googleEventID: Joi.string().min(1).max(40).allow(null, '').required(),
      links: super.validator().links,
    };
  }

  parseData(data){
    super.parseData(data);
    this.key = data.eventUUID;
    this.eventUUID = data.eventUUID;
    this.summary = data.summary;
    this.description = data.description;
    this.creationDate = data.creationDate;
    this.lastChangedDate = data.lastChangedDate;
    this.colorID = data.colorID;
    this.relatedNodeUUID = data.relatedNodeUUID;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.googleEventID = data.googleEventID;
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
