
import Note from './Note';
import Notes from './Notes';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: Note,
      list: Notes,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'Notes',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/notes`,
        getLink:    `https://${serverConfig.blldomain}/api/notes/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/notes`,
      },
    };
  }

}
