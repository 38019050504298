

var loadedModules = {
  tables: [
    {
      tableName: 'Tasks',
      key: {
        keyPath: 'nodeUUID',
      },
      indexes: [{
        indexName: 'parrentUUID',
        keyPath: 'parrentUUID',
        objectParameters: { unique: false },
      }],
    },{
      tableName: 'Notes',
      key: {
        keyPath: 'nodeUUID',
      },
      indexes: [{
        indexName: 'parrentUUID',
        keyPath: 'parrentUUID',
        objectParameters: { unique: false },
      }],
    },{
      tableName: 'Inbox',
      key: {
        keyPath: 'nodeUUID',
      },
      indexes: [],
    },{
      tableName: 'Starred',
      key: {
        keyPath: 'nodeUUID',
      },
      indexes: [],
    },{
      tableName: 'Projects',
      key: {
        keyPath: 'nodeUUID',
      },
      indexes: [{
        indexName: 'parrentUUID',
        keyPath: 'parrentUUID',
        objectParameters: { unique: false },
      }],
    },{
      tableName: 'Events',
      key: {
        keyPath: 'eventUUID',
      },
      indexes: [],
    },{
      tableName: 'EventTimers',
      key: {
        keyPath: 'eventTimerUUID',
      },
      indexes: [],
    },{
      tableName: 'Nodes',
      key: {
        keyPath: 'nodeUUID',
      },
      indexes: [],
    },
  ],
};

export default loadedModules;
