// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import {MDCDialog} from '@material/dialog';
import TextField, {Input} from '@material/react-text-field';
//import MaterialIcon from '@material/react-material-icon';
import Button from '@material/react-button';
import {Cell, Grid, Row} from '@material/react-layout-grid';

// Other Components
import Dialogbox from '../Dialogbox';

// Hepheastus
import {Node} from '../../modules/Nodes';
import {Note} from '../../modules/Notes';
import {InboxItem} from '../../modules/Inbox';
import DataContainer from '../../modules/DataContainer';

export default class NoteEditDialog extends Component {

  state = {
    note: null,
  }

  constructor(props){
    super(props);
    this.state.note = props.note;
    this.dataContainer = props.dataContainer;
    this.inbox = props.inbox || false;
    this.dialog = null;
    this.closeDialogListener = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.dialog = new MDCDialog(document.querySelector('.mdc-dialog.viewTaskDialog'));
    this.dialog.open();
    this.dialog.listen('MDCDialog:closed', this.closeDialogListener);
  }

  componentWillUnmount() {
    if(this.dialog){
      this.dialog.unlisten('MDCDialog:closed',this.closeDialogListener);
      this.dialog = null;
    }
  }

  closeDialog(event){
    if(event.detail.action === 'save') {
      if(this.state.note.message){
        this.saveNote();
      }
    }
    ReactDOM.unmountComponentAtNode(Dialogbox.getRef().current);
  }

  async saveNote(){
    if(this.state.note.links.self){
      this.state.note.update(this.dataContainer.sessionToken, ['message','lastChangedDate']);
    }else{
      this.dataContainer.add('Notes',this.state.note);
      // Add Node
      var node = new Node();
      node.key = this.state.note.nodeUUID;
      node.nodeUUID = this.state.note.nodeUUID;
      node.nodeType = 'Notes';
      this.dataContainer.add('Nodes', node);
      // Add to Inbox (if requested)
      if(this.inbox){
        var inboxitem = new InboxItem();
        inboxitem.nodeUUID = this.state.note.nodeUUID;
        inboxitem.nodeType = this.state.note.type;
        inboxitem.includedElement = this.state.note;
        this.dataContainer.add('Inbox',inboxitem);
      }
      try{
        // Create Element
        var createdNote = await this.state.note.insert(this.dataContainer.sessionToken);
        // Update Related Elements
        node.key = createdNote.nodeUUID;
        node.nodeUUID = createdNote.nodeUUID;
        if(this.inbox){
          inboxitem.nodeUUID = createdNote.nodeUUID;
          inboxitem.insert(this.dataContainer.sessionToken);
        }
      }catch(e){
        throw e;
      }finally{
        // Node will only be added to IndexDB, can not be inserted to server.
        await node.insert(this.dataContainer.sessionToken);
      }
    }
    this.dataContainer.changeFunc();
  }

  changeNoteData(name, value){
    var note = this.state.note;
    note[name] = value;
    note.lastChangedDate = new Date().toISOString();
    this.setState({
      note: note,
    });
  }

  render(){
    return (
      <div className="mdc-dialog viewTaskDialog">
        <div className="mdc-dialog__container">
          <div className="mdc-dialog__surface">
            <Grid>
              <Row>
                <Cell columns={12}>
                  <TextField label='Message' textarea={true}>
                    <Input
                      inputType='textarea'
                      size="40"
                      value={this.state.note.message}
                      onChange={(e) => {
                        this.changeNoteData('message', e.target.value);
                      }}
                    />
                  </TextField>
                </Cell>
              </Row>
              <Row>
                <Cell columns={12}>
                  <p>
                    Last Changed:&nbsp;
                    {this.dataContainer.formatDate(this.state.note.lastChangedDate)}
                  </p>
                </Cell>
              </Row>
            </Grid>
            <footer className="mdc-dialog__actions">
              <Button data-mdc-dialog-action="close">Cancel</Button>
              <Button  data-mdc-dialog-action="save">Save</Button>
            </footer>
          </div>
        </div>
        <div className="mdc-dialog__scrim"></div>
      </div>
    );
  }
}

NoteEditDialog.propTypes = {
  note: PropTypes.instanceOf(Note),
  inbox: PropTypes.bool,
  dataContainer: PropTypes.instanceOf(DataContainer),
};
