// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material Design
import TextField, {Input} from '@material/react-text-field';
import Button from '@material/react-button';

// Hepheastus

import logo from './Ralpha.png';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.setLoginFunc = this.props.setLoginFunc;
  }

  async handleSubmit(event) {
    event.preventDefault();
    try{
      var response = await fetch(
        `https://${serverConfig.blldomain}/login`, {
          method: 'GET',
          headers: {
            'Authorization': this.state.password,
          },
        });
      var resData = await response.json();
      if(resData.login){
        this.setLoginFunc(this.state.password);
      }else{
        alert('Username and/or password is incorrect');
      }
    }catch(e){
      if(serverConfig.environment === 'development'){
        this.setLoginFunc(this.state.password);
      }
    }
  }

  render(){
    return (
      <div className='loginScreen'>
        <section>
          <img className='login-logo' src={logo} alt='Logo'/>
          <h1>Hepheastus</h1>
        </section>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <TextField label='Username' className='username'>
            <Input
              type="text"
              name="username"
              value={this.state.username}
              onChange={(e) => {
                this.setState({username: e.target.value});
              }}
              required
            />
          </TextField>
          <TextField label='Password' className='password'>
            <Input
              type="password"
              name="password"
              value={this.state.password}
              onChange={(e) => {
                this.setState({password: e.target.value});
              }}
              required
            />
          </TextField>

          <div className="button-container">
            <Button type="submit" raised={true} >Login</Button>
          </div>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  setLoginFunc: PropTypes.func,
};


export default Login;
