import API_Object from './API_Object';

export default class API_List_Object extends API_Object {

  constructor(data = null){
    super(data);
    this.key = null;
    this.keyPropertie = null;
    this.type = 'API_List_Object';
    this.listElement = null;
    this.fillDefault();
  }

  fillDefault(){
    super.fillDefault();
    if(this.listElement){
      this[this.listElement] = [];
    }
  }

  copyFrom(source){
    if(!(source instanceof this.constructor)){
      console.log('Lists are not of the same type');
    }
    this.key = source.key;
    this[this.listElement] = source[source.listElement];
  }

  indexDBData(){
    var data = [];
    data = this[this.listElement].map(element => {
      return element.indexDBData()[0];
    });
    return data;
  }

  add(object){
    if(object instanceof this.constructor.thisObject().element){
      if(!this[this.listElement].includes(object)){
        this[this.listElement].push(object);
      }
    }
  }

  remove(object){
    if(object instanceof this.constructor.thisObject().element){
      var index = this[this.listElement].indexOf(object);
      this[this.listElement].splice(index, 1);
    }
  }

  get(propertie, value){
    for(var i in this[this.listElement]){
      if(this[this.listElement][i][propertie] === value){
        return this[this.listElement][i];
      }
    }
    return null;
  }

  getAll(propertie, value){
    var classNameList = this.constructor.thisObject().list;
    var newList = new classNameList();
    var i = 0;
    if(Array.isArray(value) && value.length === 2){
      // search range
      for(i in this[this.listElement]){
        if(this[this.listElement][i][propertie] >= value[0] && this[this.listElement][i][propertie] <= value[1]){
          newList[this.listElement].push(this[this.listElement][i]);
        }
      }
    }else{
      // search by exact value
      for(i in this[this.listElement]){
        if(this[this.listElement][i][propertie] === value){
          newList[this.listElement].push(this[this.listElement][i]);
        }
      }
    }
    return newList;
  }

  length(){
    return this[this.listElement].length;
  }

  sort(propertie){
    var classNameList = this.constructor.thisObject().list;
    var newList = new classNameList();
    newList[this.listElement] = [...this[this.listElement]].sort((a, b) => {
      if(a[propertie] < b[propertie]){
        return -1;
      }
      if(a[propertie] > b[propertie]){
        return 1;
      }
      return 0;
    });
    return newList;
  }

  limit(maxItems){
    var classNameList = this.constructor.thisObject().list;
    var newList = new classNameList();
    newList[this.listElement] = this[this.listElement].slice(0,maxItems);
    return newList;
  }

  parseData(data){
    super.parseData(data);
  }

  parseFromIndexDB(data){
    super.parseFromIndexDB(data);
    this.key = null;
    var classNameElement = this.constructor.thisObject().element;
    // Filter out elements that are marked as deleted.
    this[this.listElement] = data.reduce((list,element) => {
      if(element.markedAsDeleted){
        return list;
      }
      delete element.markedAsUpdated;
      list.push(new classNameElement(element));
      return list;
    }, []);
  }

}
