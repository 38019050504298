// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material Design
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

// Other Components
import TaskCard from '../TaskCard';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import DataContainer from '../../modules/DataContainer';


class TaskCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      list: this.dataContainer.getAll('Tasks'),
      parrentUUID: props.parrentUUID,
    };
  }

  componentDidMount(){
    Snackbarbox.setFAB(true);
  }

  componentWillUnmount(){
    Snackbarbox.setFAB(false);
  }

  render(){
    return (
      <div>
        <p>Tasks</p>
        {this.state.list.items.length===0?(
          <p>No Tasks to show</p>
        ):(
          <div className='cardCollection'>
            {this.state.list.items.map(task =>(
              <TaskCard
                key={task.nodeUUID}
                task={task}
                dataContainer={this.dataContainer}
              />
            ))}
          </div>
        )}

        <Fab
          className='app-fab--absolute'
          icon={<MaterialIcon icon="add"/>}
          onClick={() => {}}
        />
      </div>
    );
  }
}

TaskCards.propTypes = {
  parrentUUID: PropTypes.string,
  dataContainer: PropTypes.instanceOf(DataContainer),
};


export default TaskCards;
