// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

// Material Design
import List, {ListItem, ListItemText} from '@material/react-list';
import Button from '@material/react-button';
import Checkbox from '@material/react-checkbox';

// Hepheastus
import DataContainer from '../../modules/DataContainer';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class Permission extends Component {

  constructor(props){
    super(props);
    this.permissions = ['tasks', 'notes','inbox','events','eventtimers','projects','nodes','telemetry','starred'];
    this.dataContainer = props.dataContainer;
    this.state = {
      scope:{
        tasks: 1,
        notes: 1,
        inbox: 1,
        events: 1,
        eventtimers: 1,
        projects: 1,
        nodes: 1,
        telemetry: 1,
        starred: 1,
      },
      gotToken: false,
    };
    this.queryObjects = queryString.parse(window.location.search);
    this.window = null;
    if(this.queryObjects.code){
      this.requestToken(this.queryObjects.code, this.queryObjects.state);
    }
  }

  requestToken(code, state){
    console.log('Request Token');
    var body = JSON.stringify({
      'code':code,
      'serviceUUID': serverConfig.oauth.storage.serviceUUID, // Hephaestus
      'user':{
        'id': serverConfig.oauth.userUUID,
      },
      'state':state,
    });

    fetch(`https://${serverConfig.blldomain}/auth/code`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': this.dataContainer.sessionToken,
      },
      body: body,
    }).then(response => response.json())
      .then(response => {
        if(response.message === 'Accepted'){
          window.postMessage('Accepted',`https://${serverConfig.domain}/permissions`);
        }
      });
  }

  getScope(){
    var scope = '';
    for(var i in this.state.scope){
      if(this.state.scope[i]===1){
        scope += i+':read;'+i+':write;';
      }
    }
    return scope;
  }

  changeData(name, value){
    var scope = this.state.scope;
    scope[name] = value;
    this.setState({
      scope: scope,
      gotToken: false,
    });
  }

  getPermission() {
    var url = `https://${serverConfig.oauthdomain}/oauth/authorize?response_type=code&`+
    'client_id=627b3c5e-33a6-4a14-8c63-553a1f42e5f9&state=aaa&scope='+this.getScope()+'&'+
    `redirect_uri=${serverConfig.oauth.storage.redirectURI}`;
    this.window = window.open(url, '', 'width=400,height=400');
    this.window.addEventListener('message', e => {
      if (e.origin === `https://${serverConfig.domain}` && e.source === this.window){
        if(e.data === 'Accepted'){
          this.setState({gotToken:true});
          this.window.close();
        }
      }
    });
  }

  render(){
    if(window.location.pathname === '/permissions/code'){
      return (<p>...Wait a second...</p>);
    }

    return (
      <div>
        <p>Ask permission:</p>
        <List dense checkboxList selectedIndex={[]}>
          {this.permissions.map(permission =>(
            <ListItem key={permission}>
              <Checkbox
                nativeControlId={'checkbox-'+permission}
                checked={this.state.scope[permission]===1}
                onChange={(e) => {
                  this.changeData(permission, e.target.checked?1:0);
                }}
                onClick={(e)=>{
                  e.stopPropagation();
                }}
              />
              <ListItemText
                primaryText={permission}
              />
            </ListItem>
          ))}
        </List>
        <p>Scope: {this.getScope()}</p>
        <Button
          onClick={() => {this.getPermission();}}
          raised icon={<Checkbox checked={this.state.gotToken} disabled/>}
          disabled={this.state.gotToken}
        >
          Ask permission
        </Button>

      </div>
    );
  }
}

Permission.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
