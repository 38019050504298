// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Card, {
  CardPrimaryContent,
  CardActions,
  CardActionIcons,
} from '@material/react-card';
import MaterialIcon from '@material/react-material-icon';
import IconButton from '@material/react-icon-button';

// Other Component
import EventTimerEditDialog from '../EventTimerEditDialog';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {EventTimer} from '../../modules/EventTimers';
import DataContainer from '../../modules/DataContainer';


export default class EventTimerCard extends Component {

  constructor(props){
    super(props);
    this.state = {
      eventTimer: props.eventTimer,
      element: null,
      runningTime: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
    };
    this.dataContainer = props.dataContainer;
    this.refreshTimer = null;
    this.dataChangedListener = this.dataChange.bind(this);
  }

  saveEventTimer(){
    if(this.state.eventTimer.links.self){
      this.state.eventTimer.update(this.dataContainer.sessionToken, ['message','lastChangedDate']);
    }else{
      this.state.eventTimer.insert(this.dataContainer.sessionToken);
      this.dataContainer.add('Notes',this.state.eventTimer);
    }
  }

  dataChange(e){
    if(['Events','Nodes','Tasks','Projects','Notes'].includes(e.detail.datatype)){
      this.getElement();
    }
  }

  changeEventTimerData(name, value){
    var eventTimer = this.state.eventTimer;
    eventTimer[name] = value;
    this.setState({
      eventTimer: eventTimer,
    });
  }

  componentDidMount(){
    window.addEventListener('dataContainerChange', this.dataChangedListener);
    this.getElement();
    this.recalculateTimer();
    this.refreshTimer = setInterval(
      () => this.recalculateTimer(),
      30000
    );
  }

  componentWillUnmount() {
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
    clearInterval(this.refreshTimer);
  }

  recalculateTimer() {
    var startDate = new Date(this.state.eventTimer.startDate);
    var now = new Date();
    var timerunning = now - startDate;
    var runningTime = {
      days: 0,
      hours: 0,
      minutes: 0,
    };
    runningTime.days = Math.floor(timerunning / (1000*60*60*24));
    timerunning -= runningTime.days * (1000*60*60*24);
    runningTime.hours = Math.floor(timerunning / (1000*60*60));
    timerunning -= runningTime.hours * (1000*60*60);
    runningTime.minutes = Math.floor(timerunning / (1000*60));

    this.setState({
      runningTime: runningTime,
    });
  }

  async getElement(){
    if(!this.state.eventTimer.eventUUID){
      return;
    }
    this.event = await this.dataContainer.get('Events', 'eventUUID', this.state.eventTimer.eventUUID);
    if(!this.event){
      return;
    }
    this.node = await this.dataContainer.get('Nodes', 'nodeUUID', this.event.relatedNodeUUID);
    if(!this.node){
      return;
    }
    var element = await this.dataContainer.get(this.node.nodeType, 'nodeUUID', this.node.nodeUUID);
    this.setState({element: element});
  }

  static openEditEventTimerDialog(eventTimer,dataContainer){
    var eventTimerdialog = <EventTimerEditDialog eventTimer={eventTimer} dataContainer={dataContainer}/>;
    ReactDOM.render(eventTimerdialog, Dialogbox.getRef().current);
  }

  stopTimer(){
    Snackbarbox.show({
      message: 'Timer stopped',
    });
    this.changeEventTimerData('endDate',new Date().toISOString());
    this.state.eventTimer.stopTimer(this.dataContainer.sessionToken);
    this.dataContainer.remove('EventTimers',this.state.eventTimer);
  }

  render(){
    var elementInfo = '';
    if(this.state.element){
      if(this.state.element.name){
        elementInfo = (<p>{this.state.element.type}: {this.state.element.name}</p>);
      }else if(this.state.element.message){
        elementInfo = (<p>{this.state.element.type}: {this.state.element.message}</p>);
      }
    }

    return (
      <Card>
        <CardPrimaryContent>
          <p><MaterialIcon icon="play_arrow"/>: {this.dataContainer.formatDate(new Date(this.state.eventTimer.startDate))}</p>
          <p><MaterialIcon icon="timelapse"/>: Days: {this.state.runningTime.days}, Hours: {this.state.runningTime.hours}, Minutes: {this.state.runningTime.minutes}</p>
          {elementInfo}
        </CardPrimaryContent>
        <CardActions>
          <CardActionIcons>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                EventTimerCard.openEditEventTimerDialog(this.state.eventTimer,this.dataContainer);
              }}>
              <MaterialIcon icon="edit"/>
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                this.stopTimer();
              }}>
              <MaterialIcon icon="stop"/>
            </IconButton>
          </CardActionIcons>
        </CardActions>
      </Card>
    );
  }
}

EventTimerCard.propTypes = {
  eventTimer: PropTypes.instanceOf(EventTimer),
  dataContainer: PropTypes.instanceOf(DataContainer),

};
