import {API_Element_Object} from '../API_Object';
import Joi from '@hapi/joi';

import ModuleProperties from './ModuleProperties';

export default class Project extends API_Element_Object {

  constructor(data = null){
    super(data);
    this.type = 'project';
    this.keyPropertie = 'nodeUUID';
    this.validator = this.constructor.validator();
    this.insertProperties = ['nodeUUID','name','status','parrentUUID','creationDate',
      'lastChangedDate','dueDate','completedDate','colorID','icon','prefix','active'];
    this.updateProperties = ['name','status','parrentUUID',
      'lastChangedDate','dueDate','completedDate','colorID','icon','prefix','active'];
    this.storeProperties = ['type','nodeUUID','name','status','parrentUUID','creationDate',
      'lastChangedDate','dueDate','completedDate','colorID','icon','prefix','active','links'];
    if(data != null){
      this.parseData(data);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
    this.nodeUUID = this.key;
    this.name = '';
    this.status = 0;
    this.parrentUUID = null;
    this.creationDate = new Date().toISOString();
    this.lastChangedDate = new Date().toISOString();
    this.dueDate = null;
    this.completedDate = null;
    this.colorID = null;
    this.icon = null;
    this.prefix = null;
    this.active = 1;
  }

  static validator(){
    return {
      type: Joi.string().valid('project').required(),
      nodeUUID: Joi.string().guid({version:'uuidv4'}).required(),
      name: Joi.string().min(1).max(256).required(),
      status: Joi.number().min(0).max(10).required(),
      parrentUUID: Joi.string().guid({version:'uuidv4'}).allow(null).required(),
      creationDate: Joi.date().iso().required(),
      lastChangedDate: Joi.date().iso().required(),
      dueDate: Joi.date().iso().allow(null).required(),
      completedDate: Joi.date().iso().allow(null).required(),
      colorID: Joi.string().min(1).max(6).allow(null).required(),
      icon: Joi.string().min(0).max(30).allow(null,'').required(),
      prefix: Joi.string().min(0).max(3).allow(null,'').required(),
      active: Joi.number().min(0).max(1).required(),
      links: super.validator().links,
    };
  }

  parseData(data){
    super.parseData(data);
    this.key = data.nodeUUID;
    this.nodeUUID = data.nodeUUID;
    this.name = data.name;
    this.status = data.status;
    this.parrentUUID = data.parrentUUID;
    this.creationDate = data.creationDate;
    this.lastChangedDate = data.lastChangedDate;
    this.dueDate = data.dueDate;
    this.completedDate = data.completedDate;
    this.colorID = data.colorID;
    this.icon = data.icon;
    this.prefix = data.prefix;
    this.active = data.active;
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
