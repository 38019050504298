import {Tasks, Task} from '../Tasks';
import {Notes, Note} from '../Notes';
import {Inbox, InboxItem} from '../Inbox';
import {Starred, StarredItem} from '../Starred';
import {Events as CalEvents, Event as CalEvent} from '../Events';
import {EventTimers, EventTimer} from '../EventTimers';
import {Nodes, Node} from '../Nodes';
import {Projects, Project} from '../Projects';
import {CountTelemetry, CountTelemetryItem} from '../CountTelemetry';

import API_Object from '../API_Object';

export default class DataContainer {

  constructor(changeFunc){
    this.sessionToken = null;
    this.data = [
      {
        name: 'Projects',
        listObj: new Projects(),
        listProm: null,
        listClass: Projects,
        oneClass: Project,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'Tasks',
        listObj: new Tasks(),
        listProm: null,
        listClass: Tasks,
        oneClass: Task,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'Notes',
        listObj: new Notes(),
        listProm: null,
        listClass: Notes,
        oneClass: Note,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'Inbox',
        listObj: new Inbox(),
        listProm: null,
        listClass: Inbox,
        oneClass: InboxItem,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'Starred',
        listObj: new Starred(),
        listProm: null,
        listClass: Starred,
        oneClass: StarredItem,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'Events',
        listObj: new CalEvents(),
        listProm: null,
        listClass: CalEvents,
        oneClass: CalEvent,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'EventTimers',
        listObj: new EventTimers(),
        listProm: null,
        listClass: EventTimers,
        oneClass: EventTimer,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'Nodes',
        listObj: new Nodes(),
        listProm: null,
        listClass: Nodes,
        oneClass: Node,
        autorequest: true,
        useIndexDB: true,
      },{
        name: 'CountTelemetry',
        listObj: new CountTelemetry(),
        listProm: null,
        listClass: CountTelemetry,
        oneClass: CountTelemetryItem,
        autorequest: false,
        useIndexDB: false,
      },
    ];
    this.changeFunc = changeFunc;
  }

  formatDate(date){
    if(typeof date === 'string' || date instanceof String){
      date = new Date(date);
    }
    var d = {
      year: date.getFullYear(),
      month: date.getMonth()+1,
      day: date.getDate(),
      hour: date.getHours(),
      minutes: date.getMinutes(),
    };
    return `${d.year}/${d.month}/${d.day} ${d.hour}:${d.minutes}`;
  }

  setSessionToken(newToken){
    this.sessionToken = newToken;
  }

  async doSync(){
    var error = null;
    var counts = await Promise.all(this.data.map(async element => {
      var count = 0;
      if(element.useIndexDB){
        try{
          count = await element.listClass.syncIndexDBandServer(this.sessionToken);
        }catch(err){
          console.log('Sync Error',err);
          error = err;
          count = 1;
        }
      }
      return count;
    }));
    console.log(counts);
    var toUpdateCount = counts.reduce((a, b) => a + b, 0);
    if(toUpdateCount === 0 && !error){
      // clearIndexDB
      API_Object.clearAllInIndexDB();
      // Request all new items
      this.requestAll();
      return 'Sync Complete, Cleared cache.';
    }
    // return
    if(error){
      if(error.message === 'NetworkError when attempting to fetch resource.'){
        return 'Sync with error: could not connect to server';
      }
      return `Sync with error: ${error.message}`;
    }
    return `Sync Complete, updated ${toUpdateCount} items.`;
  }

  requestAll(autorequestAll = false){
    var changeFunc = this.changeFunc;
    var sessionToken = this.sessionToken;
    this.data.forEach(element => {
      if(element.autorequest || autorequestAll){
        element.listProm = new Promise(async function(resolve, reject){
          if(element.useIndexDB){
            try{
              await element.listClass.syncIndexDBandServer(sessionToken);
            }catch(err){
              console.log('DataContainer',err);
            }
          }
          var data = await element.listClass.list(sessionToken);
          element.listObj.copyFrom(data);
          changeFunc();
          window.dispatchEvent(new CustomEvent('dataContainerChange', { detail: {datatype: element.name}} ));
          resolve(data);
        });
      }
    });
  }

  requestData(name){
    var index = null;
    for(var i in this.data){
      if(this.data[i].name === name){
        index = i;
        break;
      }
    }
    if(index){
      var changeFunc = this.changeFunc;
      var sessionToken = this.sessionToken;
      var element = this.data[index];
      var datatype = name;
      element.listProm = new Promise(async function(resolve, reject){
        if(element.useIndexDB){
          try{
            await element.listClass.syncIndexDBandServer(sessionToken);
          }catch(err){
            console.log('DataContainer',err);
          }
        }
        try{
          var data = await element.listClass.list(sessionToken);
          element.listObj.copyFrom(data);
          changeFunc();
          window.dispatchEvent(new CustomEvent('dataContainerChange', { detail: {datatype: datatype}} ));
          resolve(data);
        }catch(err){
          // fail silently
          //console.log('test',err);
        }
      });
    }
  }

  getAll(name, propertie=null, value=null){
    var index = null;
    for(var i in this.data){
      if(this.data[i].name === name){
        index = i;
        break;
      }
    }
    if(index){
      if(!this.data[index].listProm){
        console.log('Data not requested yet');
        this.requestData(name);
        return this.data[index].listObj;
      }
      if(!propertie){
        return this.data[index].listObj;
      }
      return this.data[index].listObj.getAll(propertie, value);
    }
    console.log('Propertie "'+name+'" not defined in DataContainer');
    return null;
  }

  add(name, object){
    var index = null;
    for(var i in this.data){
      if(this.data[i].name === name){
        index = i;
        break;
      }
    }
    if(index){
      if(!this.data[index].listObj){
        console.log('Data not requested yet');
        return this.data[index].listObj;
      }
      // Add data if already loaded
      this.data[index].listObj.add(object);
      this.changeFunc();
      window.dispatchEvent(new CustomEvent('dataContainerChange', { detail: {datatype: name}} ));
      return null;
    }
    console.log('Propertie "'+name+'" not defined in DataContainer');
    return null;
  }

  remove(name, object){
    var index = null;
    for(var i in this.data){
      if(this.data[i].name === name){
        index = i;
        break;
      }
    }
    if(index){
      if(!this.data[index].listObj){
        console.log('Data not requested yet');
        return this.data[index].listObj;
        //throw new Error('Data not requested yet');
      }
      this.data[index].listObj.remove(object);
      this.changeFunc();
      window.dispatchEvent(new CustomEvent('dataContainerChange', { detail: {datatype: name}} ));
      return null;
    }
    console.log('Propertie "'+name+'" not defined in DataContainer');
    return null;
  }

  get(name, propertie, value){
    var index = null;
    for(var i in this.data){
      if(this.data[i].name === name){
        index = i;
        break;
      }
    }
    if(index){
      if(!this.data[index].listObj){
        console.log('Data not requested yet');
        return this.data[index].listObj.get(propertie, value);
      }
      return this.data[index].listObj.get(propertie, value);
    }
    console.log('Propertie "'+name+'" not defined in DataContainer');
    return null;
  }

}
