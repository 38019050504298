import {API_Element_Object} from '../API_Object';
import Joi from '@hapi/joi';

import ModuleProperties from './ModuleProperties';

export default class Note extends API_Element_Object {

  constructor(data = null){
    super(data);
    this.type = 'note';
    this.keyPropertie = 'nodeUUID';
    this.validator = this.constructor.validator();
    this.insertProperties = ['nodeUUID','message','creationDate','parrentUUID',
      'lastChangedDate'];
    this.updateProperties = ['message','parrentUUID',
      'lastChangedDate'];
    this.storeProperties = ['type','nodeUUID','message','creationDate','parrentUUID',
      'lastChangedDate','links'];
    if(data != null){
      this.parseData(data);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
    this.nodeUUID = this.key;
    this.message = '';
    this.creationDate = new Date().toISOString();
    this.parrentUUID = null;
    this.lastChangedDate = new Date().toISOString();
  }

  static validator(){
    return {
      type: Joi.string().valid('note').required(),
      nodeUUID: Joi.string().guid({version:'uuidv4'}).required(),
      message: Joi.string().min(1).max(65535).required(),
      creationDate: Joi.date().iso().required(),
      parrentUUID: Joi.string().guid({version:'uuidv4'}).allow(null).required(),
      lastChangedDate: Joi.date().iso().required(),
      links: super.validator().links,
    };
  }

  parseData(data){
    super.parseData(data);
    this.key = data.nodeUUID;
    this.nodeUUID = data.nodeUUID;
    this.message = data.message;
    this.creationDate = data.creationDate;
    this.parrentUUID = data.parrentUUID;
    this.lastChangedDate = data.lastChangedDate;
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
