import {API_Element_Object} from '../API_Object';
import Joi from '@hapi/joi';

import ModuleProperties from './ModuleProperties';
import {NodeTools} from '../Nodes';

export default class StarredItem extends API_Element_Object {

  constructor(data = null, included = null){
    super(data);
    this.type = 'starreditem';
    this.keyPropertie = 'nodeUUID';
    this.validator = this.constructor.validator();
    this.insertProperties = ['nodeUUID'];
    this.updateProperties = ['nodeUUID'];
    this.storeProperties = ['type','nodeUUID','nodeType','links'];
    if(included == null){
      included = [];
    }
    if(data != null){
      this.parseData(data, included);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
    this.nodeUUID = this.key;
    this.nodeType = null;
    this.includedElement = null;
  }

  static validator(){
    return {
      type: Joi.string().valid('starreditem').required(),
      nodeUUID: Joi.string().guid({version:'uuidv4'}).required(),
      nodeType: Joi.string().min(1).max(30).required(),
      data: Joi.object({
        type: Joi.string().valid('task','note','project').required(),
        nodeUUID: Joi.string().guid({version:'uuidv4'}).required(),
      }),
      links: super.validator().links,
    };
  }

  parseData(data, included){
    super.parseData(data);
    this.key = data.nodeUUID;
    this.nodeUUID = data.nodeUUID;
    this.nodeType = data.nodeType;
    this.includedElement = null;

    if(!Array.isArray(included)){
      return;
    }
    for(var i in included){
      if(included[i].hasOwnProperty('nodeUUID') && included[i].hasOwnProperty('type') &&
         included[i].nodeUUID === this.nodeUUID){
        this.includedElement = NodeTools.createNodeByType(included[i], included[i].type);
        break;
      }
    }
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
