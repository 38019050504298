// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

// Other Components
import NoteCard from '../NoteCard';
import TaskCard from '../TaskCard';
import ProjectCard from '../ProjectCard';
import NodeBreadcrumbs from '../NodeBreadcrumbs';
import Showdown from 'showdown';
import TaskEditDialog from '../TaskEditDialog';
import NoteEditDialog from '../NoteEditDialog';
import ProjectEditDialog from '../ProjectEditDialog';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Task} from '../../modules/Tasks';
import {Note} from '../../modules/Notes';
import {Project} from '../../modules/Projects';
import DataContainer from '../../modules/DataContainer';


export default class NodeCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    var parrentUUID = props.parrentUUID || props.match.params.nodeuuid || null;
    this.state = {
      lists: {
        tasks: this.dataContainer.getAll('Tasks', 'parrentUUID', parrentUUID),
        notes: this.dataContainer.getAll('Notes', 'parrentUUID', parrentUUID),
        projects: this.dataContainer.getAll('Projects', 'parrentUUID', parrentUUID),
      },
      parrentUUID: parrentUUID,
      openFabCollection: false,
    };
    // SECURITY: Check settings
    this.markdownConverter = new Showdown.Converter({
      noHeaderId: true,
      headerLevelStart: 2,
      simplifiedAutoLink: true,
      strikethrough: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true,
      backslashEscapesHTMLTags: true,
    });
    this.dataChangeListener = this.dataChange.bind(this);
  }

  openAddTaskDialog(){
    var task = new Task();
    task.parrentUUID = this.state.parrentUUID;
    var taskdialog = <TaskEditDialog task={task} inbox={false} dataContainer={this.dataContainer}/>;
    ReactDOM.render(taskdialog, Dialogbox.getRef().current);
  }

  openAddNoteDialog(){
    var note = new Note();
    note.parrentUUID = this.state.parrentUUID;
    var notedialog = <NoteEditDialog note={note} inbox={false} dataContainer={this.dataContainer}/>;
    ReactDOM.render(notedialog, Dialogbox.getRef().current);
  }

  openAddProjectDialog(){
    var project = new Project();
    project.parrentUUID = this.state.parrentUUID;
    var projectdialog = <ProjectEditDialog project={project} inbox={false} dataContainer={this.dataContainer}/>;
    ReactDOM.render(projectdialog, Dialogbox.getRef().current);
  }

  setParrentUUID(parrentUUID){
    if(parrentUUID !== null){
      this.props.history.push('/nodes/'+parrentUUID);
    }else{
      this.props.history.push('/nodes');
    }
    this.setState({
      parrentUUID: parrentUUID,
      lists: {
        tasks: this.dataContainer.getAll('Tasks', 'parrentUUID', parrentUUID),
        notes: this.dataContainer.getAll('Notes', 'parrentUUID', parrentUUID),
        projects: this.dataContainer.getAll('Projects', 'parrentUUID', parrentUUID),
      },
    });
  }

  dataChange(e){
    if(['Nodes','Tasks','Projects'].includes(e.detail.datatype)){
      this.setState({
        lists: {
          tasks: this.dataContainer.getAll('Tasks', 'parrentUUID', this.state.parrentUUID),
          notes: this.dataContainer.getAll('Notes', 'parrentUUID', this.state.parrentUUID),
          projects: this.dataContainer.getAll('Projects', 'parrentUUID', this.state.parrentUUID),
        },
      });
    }
  }

  componentDidMount(){
    Snackbarbox.setFAB(true);
    window.addEventListener('dataContainerChange', this.dataChangeListener);
  }

  componentWillUnmount(){
    Snackbarbox.setFAB(false);
    window.removeEventListener('dataContainerChange', this.dataChangeListener);
  }

  render(){
    return (
      <div>
        <NodeBreadcrumbs
          setParrentUUID={this.setParrentUUID.bind(this)}
          currentNodeUUID={this.state.parrentUUID}
          dataContainer={this.dataContainer}
        />
        <h3>Projects</h3>
        <div className='cardCollection'>
          {this.state.lists.projects.getAll('active', 1).items.map(element =>{
            return (
              <ProjectCard
                key={element.nodeUUID}
                project={element}
                dataContainer={this.dataContainer}
                setParrentUUID={this.setParrentUUID.bind(this)}
              />
            );
          })}
        </div>
        <h3>Tasks</h3>
        <div className='cardCollection'>
          {this.state.lists.tasks.sort('status').items.map(element =>{
            return (
              <TaskCard
                key={element.nodeUUID}
                task={element}
                dataContainer={this.dataContainer}
                setParrentUUID={this.setParrentUUID.bind(this)}
              />
            );
          })}
        </div>
        <h3>Notes</h3>
        <div className='cardCollection'>
          {this.state.lists.notes.items.map(element =>{
            return (
              <NoteCard
                key={element.nodeUUID}
                note={element}
                dataContainer={this.dataContainer}
                markdownConverter={this.markdownConverter}
                setParrentUUID={this.setParrentUUID.bind(this)}
              />
            );
          })}
        </div>
        {this.state.parrentUUID!==null && (<div>
          <h3>Projects (inactive)</h3>
          <div className='cardCollection'>
            {this.state.lists.projects.getAll('active', 0).items.map(element =>{
              return (
                <ProjectCard
                  key={element.nodeUUID}
                  project={element}
                  dataContainer={this.dataContainer}
                  setParrentUUID={this.setParrentUUID.bind(this)}
                />
              );
            })}
          </div>
        </div>)}
        <div className='app-fab-collection--absolute'>
          <div className={'fab-collection ' + (this.state.openFabCollection?'':'fab-collection--inactive')}>
            <Fab
              mini
              icon={<MaterialIcon icon="playlist_add_check"/>}
              onClick={() => this.openAddTaskDialog()}
            />
            <Fab
              mini
              icon={<MaterialIcon icon="note"/>}
              onClick={() => this.openAddNoteDialog()}
            />
            <Fab
              mini
              icon={<MaterialIcon icon="insert_drive_file"/>}
              onClick={() => this.openAddProjectDialog()}
            />
          </div>
          <Fab
            icon={<MaterialIcon icon={this.state.openFabCollection?'close':'add'}/>}
            onClick={() => this.setState({openFabCollection: !this.state.openFabCollection})}
          />
        </div>
      </div>
    );
  }
}


NodeCards.propTypes = {
  parrentUUID: PropTypes.string,
  dataContainer: PropTypes.instanceOf(DataContainer),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};
