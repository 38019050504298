// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

// Other Components
import NoteCard from '../NoteCard';
import Showdown from 'showdown';
import NoteEditDialog from '../NoteEditDialog';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Note} from '../../modules/Notes';
import DataContainer from '../../modules/DataContainer';


class NoteCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      list: this.dataContainer.getAll('Notes'),
      parrentUUID: props.parrentUUID,
    };
    // SECURITY: Check settings
    this.markdownConverter = new Showdown.Converter({
      noHeaderId: true,
      headerLevelStart: 2,
      simplifiedAutoLink: true,
      strikethrough: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true,
      backslashEscapesHTMLTags: true,
    });
  }

  openAddNoteDialog(){
    var note = new Note();
    note.parrentUUID = null;
    var notedialog = <NoteEditDialog note={note} inbox={false} dataContainer={this.dataContainer}/>;
    ReactDOM.render(notedialog, Dialogbox.getRef().current);
  }

  componentDidMount(){
    Snackbarbox.setFAB(true);
  }

  componentWillUnmount(){
    Snackbarbox.setFAB(false);
  }

  render(){
    return (
      <div>
        <p>Notes</p>
        {this.state.list.items.length===0?(
          <p>No Notes to show</p>
        ):(
          <div className='cardCollection'>
            {this.state.list.items.map(note =>(
              <NoteCard
                key={note.nodeUUID}
                note={note}
                dataContainer={this.dataContainer}
                markdownConverter={this.markdownConverter}
              />
            ))}
          </div>
        )}

        <Fab
          className='app-fab--absolute'
          icon={<MaterialIcon icon="add"/>}
          onClick={() => this.openAddNoteDialog()}
        />
      </div>
    );
  }
}

NoteCards.propTypes = {
  parrentUUID: PropTypes.string,
  dataContainer: PropTypes.instanceOf(DataContainer),

};


export default NoteCards;
