const crypto = require('crypto');

export default class UUID{
  static s(number){
    var buf = crypto.randomBytes(number);
    return buf.toString('hex').substr(-number*2);
  }
  static uuid() {
    return this.s(4) + '-' + this.s(2) + '-4' + this.s(2).substr(1) +
      '-8' + this.s(2).substr(1) + '-' + this.s(6);
  }
}
