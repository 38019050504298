import {API_Element_Object} from '../API_Object';
import Joi from '@hapi/joi';

import ModuleProperties from './ModuleProperties';

export default class Node extends API_Element_Object {

  constructor(data = null){
    super(data);
    this.type = 'node';
    this.keyPropertie = 'nodeUUID';
    this.validator = this.constructor.validator();
    this.insertProperties = [];
    this.updateProperties = [];
    this.storeProperties = ['type','nodeUUID','nodeType','links'];
    if(data != null){
      this.parseData(data);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
    this.nodeUUID = this.key;
    this.nodeType = null;
  }

  static validator(){
    return {
      type: Joi.string().valid('node').required(),
      nodeUUID: Joi.string().guid({version:'uuidv4'}).required(),
      nodeType: Joi.string().min(1).max(30).required(),
      links: super.validator().links,
    };
  }

  parseData(data){
    super.parseData(data);
    this.key = data.nodeUUID;
    this.nodeUUID = data.nodeUUID;
    this.nodeType = data.nodeType;
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
