// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material Design
import MaterialIcon from '@material/react-material-icon';
import Card, {
  CardPrimaryContent,
  CardActions,
  CardActionIcons,
} from '@material/react-card';
import IconButton from '@material/react-icon-button';
import { Cell, Grid, Row } from '@material/react-layout-grid';

// Other Components
import DElementCount from '../DElementCount';
import DElementCountTime from '../DElementCountTime';
import DElementsDueSoon from '../DElementsDueSoon';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import DataContainer from '../../modules/DataContainer';
import API_Object from '../../modules/API_Object';


export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.dataContainer = props.dataContainer;
  }

  clearDatabase() {
    console.log('clearing db.');
    API_Object.clearAllInIndexDB();

    Snackbarbox.setFAB(true);
    Snackbarbox.show({
      message: 'cleared local database',
      timeout: 10000,
      actionHandler: () => { },
      actionText: null,
      multiline: false,
      actionOnBottom: false,
    });
  }

  render() {
    return (
      <div className='dashboard'>
        <Grid>
          <Row>
            <Cell desktopColumns={3} phoneColumns={4}>
              <DElementsDueSoon dataContainer={this.dataContainer} />
            </Cell>
            <Cell desktopColumns={4} phoneColumns={4}>
              <DElementCount dataContainer={this.dataContainer} />
            </Cell>
            <Cell desktopColumns={4} phoneColumns={4}>
              <DElementCountTime dataContainer={this.dataContainer} />
            </Cell>
            <Cell desktopColumns={4} phoneColumns={4}>
              <Card>
                <CardPrimaryContent>
                  <i className='mdi mdi-worker' />
                  <MaterialIcon icon="home" />
                </CardPrimaryContent>
                <CardActions>
                  <CardActionIcons>
                    <IconButton onClick={() => { this.clearDatabase(); }}>
                      <MaterialIcon icon="stop" />
                    </IconButton>
                  </CardActionIcons>
                </CardActions>
              </Card>
            </Cell>
          </Row>
        </Grid>

      </div>
    );
  }
}

Dashboard.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
