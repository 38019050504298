// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js';
// Material Design
import Card from '@material/react-card';

// Other Components

// Hepheastus
import DataContainer from '../../modules/DataContainer';


export default class DElementCountTime extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.labels = ['Inbox','Tasks','Notes','Projects','Starred'];
    this.countTypes = ['Inbox','Tasks','Notes','Projects','Starred'];
    this.state = {
      countlist: this.dataContainer.getAll('CountTelemetry'),
    };
    this.chartColors = [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
      'rgba(75, 192, 192, 1)',
    ];
    this.ref = React.createRef();
    this.dataChangedListener = this.dataChange.bind(this);
  }

  dataChange(e){
    if(['CountTelemetry'].includes(e.detail.datatype)){
      if(this.chart){
        this.chart.data.datasets = this.formatData();
        this.chart.update();
      }
    }
  }

  formatData(){
    var dataSetList = [];
    for(var i in this.countTypes){
      var sublist = this.state.countlist.getAll('countType', this.countTypes[i]);
      var dataList = [];
      for(var j in sublist[sublist.listElement]){
        dataList.push({
          x: new Date(sublist[sublist.listElement][j].date),
          y: sublist[sublist.listElement][j].count,
        });
      }
      dataSetList.push({
        label: this.countTypes[i],
        backgroundColor: this.chartColors[i],
        borderColor: this.chartColors[i],
        fill: false,
        lineTension: 0,
        data: dataList,
      });
    }
    return dataSetList;
  }

  componentDidMount(){
    var ctx = this.ref.current;
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: this.formatData(),
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero:true,
            },
          }],
          xAxes: [{
            type: 'time',
            time: {
              unit: 'day',//'day'
            },
          }],
        },
      },
    });
    window.addEventListener('dataContainerChange', this.dataChangedListener);
  }

  componentWillUnmount(){
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
  }

  render(){
    return (
      <Card>
        <canvas ref={this.ref} width="400" height="400"></canvas>
      </Card>
    );
  }
}

DElementCountTime.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
