// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

// Other Components
import ProjectCard from '../ProjectCard';
import ProjectEditDialog from '../ProjectEditDialog';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Project} from '../../modules/Projects';
import DataContainer from '../../modules/DataContainer';


export default class ProjectCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      list: this.dataContainer.getAll('Projects'),
    };
  }

  openAddProjectDialog(){
    var project = new Project();
    project.parrentUUID = null;
    var projectdialog = <ProjectEditDialog project={project} inbox={false} dataContainer={this.dataContainer}/>;
    ReactDOM.render(projectdialog, Dialogbox.getRef().current);
  }

  componentDidMount(){
    Snackbarbox.setFAB(true);
  }

  componentWillUnmount(){
    Snackbarbox.setFAB(false);
  }

  render(){
    return (
      <div>
        <p>Projects</p>
        {this.state.list.items.length===0?(
          <p>No Projects to show</p>
        ):(
          <div className='cardCollection'>
            {this.state.list.items.map(project =>(
              <ProjectCard
                key={project.nodeUUID}
                project={project}
                dataContainer={this.dataContainer}
              />
            ))}
          </div>
        )}

        <Fab
          className='app-fab--absolute'
          icon={<MaterialIcon icon="add"/>}
          onClick={() => this.openAddProjectDialog()}
        />
      </div>
    );
  }
}

ProjectCards.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
