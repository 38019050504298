import {API_Element_Object} from '../API_Object';
import Joi from '@hapi/joi';


import {Event} from '../Events';
import ModuleProperties from './ModuleProperties';

export default class EventTimer extends API_Element_Object {

  constructor(data = null, included = null){
    super(data);
    this.type = 'eventtimer';
    this.keyPropertie = 'eventTimerUUID';
    this.validator = this.constructor.validator();
    this.insertProperties = ['eventTimerUUID','startDate','endDate','eventUUID','nodeUUID'];
    this.updateProperties = ['startDate','endDate'];
    this.storeProperties = ['type','eventTimerUUID','startDate','endDate',
      'eventUUID','links','nodeUUID'];
    if(included == null){
      included = [];
    }
    if(data != null){
      this.parseData(data, included);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
    this.eventTimerUUID = this.key;
    this.startDate = new Date().toISOString();
    this.endDate = new Date().toISOString();
    this.eventUUID = null;
    this.includedElement = null;
  }

  static validator(){
    return {
      type: Joi.string().valid('eventtimer').required(),
      eventTimerUUID: Joi.string().guid({version:'uuidv4'}).required(),
      startDate: Joi.date().iso().required(),
      endDate: Joi.date().iso().allow(null).required(),
      eventUUID: Joi.string().guid({version:'uuidv4'}).allow(null).required(),
      links: super.validator().links,
      nodeUUID: Joi.string().guid({version:'uuidv4'}).allow(null).optional(),
    };
  }

  parseData(data, included){
    super.parseData(data);
    this.key = data.eventTimerUUID;
    this.eventTimerUUID = data.eventTimerUUID;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.eventUUID = data.eventUUID;
    this.nodeUUID = data.nodeUUID;

    if(!Array.isArray(included)){
      return;
    }
    for(var i in included){
      if(included[i].hasOwnProperty('eventUUID') && included[i].hasOwnProperty('type') &&
         included[i].eventUUID === this.eventUUID && included[i].type === 'event'){
        this.includedElement = new Event(included[i]);
        break;
      }
    }
  }

  async startTimer(token, nodeUUID){
    var classObjectElement = this;
    if(classObjectElement.constructor !== this.constructor.thisObject().element){
      console.log('The "startTimer()" function can only be called from ' +
        this.constructor.thisObject().element.getClass());
      throw Error('Wrong startTimer function call object.');
    }
    classObjectElement.endDate = null;
    // Insert Object in IndexDB
    var storedObject = new EventTimer();
    storedObject.key = classObjectElement.key;
    classObjectElement.nodeUUID = nodeUUID;
    await classObjectElement.updateInIndexDB(token);
    return new Promise(async function(resolve, reject) {
      var data = classObjectElement.filterProperties(classObjectElement, ['startDate']);
      try{
        var response = await fetch(
          classObjectElement.constructor.thisAPI().listLink + '/start/'+nodeUUID, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': token,
            },
            body: JSON.stringify(data),
          });
        var resData = await response.json();
        API_Element_Object.checkError(resData);
        classObjectElement.parseData(resData.data,resData.included);

        if(storedObject.key !== classObjectElement.key && classObjectElement.constructor.indexDBInfo().table){
          // Key was changed by Server
          // Remove old local copy
          API_Element_Object.removeFromIndexDB(storedObject);
        }
        await classObjectElement.updateInIndexDB(token);

        resolve(classObjectElement);
      }catch(err){
        reject(err);
      }
    });
  }

  async stopTimer(token){
    var classObjectElement = this;
    if(classObjectElement.constructor !== this.constructor.thisObject().element){
      console.log('The "stopTimer()" function can only be called from ' +
        this.constructor.thisObject().element.getClass());
      throw Error('Wrong stopTimer function call object.');
    }

    // Update Object if already exists
    var classNameElement = this.constructor.thisObject().element;
    var storedObject = new classNameElement();
    storedObject.key = classObjectElement.key;
    if(classObjectElement.constructor.indexDBInfo().table){
      await classObjectElement.updateInIndexDB(token,true);
    }
    return new Promise(async function(resolve, reject) {
      var data = classObjectElement.filterProperties(classObjectElement, ['endDate']);
      try{
        var response = await fetch(
          classObjectElement.constructor.thisAPI().listLink + '/stop/'+classObjectElement.eventTimerUUID, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': token,
            },
            body: JSON.stringify(data),
          });
        var resData = await response.json();
        API_Element_Object.checkError(resData);
        classObjectElement.parseData(resData.data,resData.included);

        if(storedObject.key !== classObjectElement.key && classObjectElement.constructor.indexDBInfo().table){
          // Key was changed by Server
          // Remove old local copy
          API_Element_Object.removeFromIndexDB(storedObject);
        }
        //await classObjectElement.updateInIndexDB(token);
        API_Element_Object.removeFromIndexDB(classObjectElement);

        resolve(classObjectElement);
      }catch(err){
        reject(err);
      }
    });
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
