// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Card, {
  CardPrimaryContent,
  CardActions,
  CardActionIcons,
} from '@material/react-card';
import MaterialIcon from '@material/react-material-icon';
import IconButton from '@material/react-icon-button';

// Other Component
import ProjectEditDialog from '../ProjectEditDialog';
import ProjectDeleteDialog from '../ProjectDeleteDialog';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Project} from '../../modules/Projects';
import DataContainer from '../../modules/DataContainer';
import {EventTimer} from '../../modules/EventTimers';
import {Event} from '../../modules/Events';
import {StarredItem} from '../../modules/Starred';


export default class ProjectCard extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      project: props.project,
      starred: this.dataContainer.get('Starred','nodeUUID',props.project.nodeUUID),
    };
    this.setParrentUUID = props.setParrentUUID;
    this.enableTimerStart = true;//Used for delay on dubbelclick
    this.dataChangedListener = this.dataChange.bind(this);
  }

  dataChange(e){
    if(['Starred'].includes(e.detail.datatype)){
      this.setState({starred: this.dataContainer.get('Starred','nodeUUID',this.state.project.nodeUUID)});
    }
  }

  componentDidMount(){
    window.addEventListener('dataContainerChange', this.dataChangedListener);
  }

  componentWillUnmount(){
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
  }

  changeProjectData(name, value){
    var project = this.state.project;
    project[name] = value;
    project.lastChangedDate = new Date().toISOString();
    this.setState({
      project: project,
    });
    this.saveProject();
  }

  saveProject(){
    if(this.state.project.links.self){
      this.state.project.update(this.dataContainer.sessionToken, ['status','lastChangedDate']);
    }
    this.dataContainer.changeFunc();
  }

  static openEditProjectDialog(project,dataContainer){
    var projectdialog = <ProjectEditDialog project={project} dataContainer={dataContainer}/>;
    ReactDOM.render(projectdialog, Dialogbox.getRef().current);
  }

  static openDeleteProjectDialog(project,dataContainer){
    var projectdialog = <ProjectDeleteDialog project={project} dataContainer={dataContainer}/>;
    ReactDOM.render(projectdialog, Dialogbox.getRef().current);
  }

  async startTimer(){
    Snackbarbox.show({
      message: 'Timer started',
    });
    var timer = new EventTimer();
    try{
      await timer.startTimer(this.dataContainer.sessionToken, this.state.project.nodeUUID);
    }catch(err){
      console.log(err);
    }
    this.dataContainer.add('EventTimers',timer);
    if(timer.includedElement && timer.includedElement instanceof Event){
      this.dataContainer.add('Events',timer.includedElement);
    }
  }

  async starItem(item, showAction = true){
    if(showAction){
      Snackbarbox.show({
        message: 'Starred',
        actionHandler: () => {this.unstarItem(item, false);},
        actionText: 'undo',
      });
    }else{
      Snackbarbox.show({
        message: 'Starred',
      });
    }
    var starreditem = new StarredItem();
    starreditem.nodeUUID = item.nodeUUID;
    starreditem.nodeType = item.type;
    starreditem.includedElement = item;
    this.dataContainer.add('Starred',starreditem);
    starreditem.insert(this.dataContainer.sessionToken);
  }

  async unstarItem(item, showAction = true){
    if(showAction){
      Snackbarbox.show({
        message: 'Removed from starred',
        actionHandler: () => {this.starItem(item, false);},
        actionText: 'undo',
      });
    }else{
      Snackbarbox.show({
        message: 'Removed from starred',
      });
    }
    var starreditem = this.dataContainer.get('Starred','nodeUUID',item.nodeUUID);
    this.dataContainer.remove('Starred',starreditem);
    starreditem.delete(this.dataContainer.sessionToken);
  }

  render(){
    return (
      <Card className='projectCard'>
        <CardPrimaryContent className='projectContent'>
          <h2 className={'mdc-typography--headline6 '+(this.state.project.active?'':'project-inactive')}>
            {this.state.project.icon && <i className={'projectIcon mdi mdi-'+this.state.project.icon}/>}
            {this.state.project.prefix?(this.state.project.prefix+': '):''}{this.state.project.name}
            {this.state.project.active?'':<i> (inactive)</i>}
          </h2>
          {this.state.project.dueDate?(
            <p className='mdc-typography--subtitle2'>Due: {this.dataContainer.formatDate(this.state.project.dueDate)}</p>
          ):''}
          {/*<p className='mdc-typography--subtitle2'>Status: {this.state.project.status}</p>*/}
        </CardPrimaryContent>
        <CardActions>
          <CardActionIcons>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              this.setParrentUUID(this.state.project.nodeUUID);
            }}>
              <MaterialIcon icon="subdirectory_arrow_right"/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              if(this.state.starred){
                this.unstarItem(this.state.project);
              }else{
                this.starItem(this.state.project);
              }
            }}>
              <MaterialIcon icon={this.state.starred?'star':'star_border'}/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              if(this.enableTimerStart){
                this.enableTimerStart = false;
                setTimeout(() => {this.enableTimerStart = true;},2000);
                this.startTimer();
              }
            }}>
              <MaterialIcon icon="play_arrow"/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              ProjectCard.openEditProjectDialog(this.state.project,this.dataContainer);
            }}>
              <MaterialIcon icon="edit"/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              ProjectCard.openDeleteProjectDialog(this.state.project,this.dataContainer);
            }}>
              <MaterialIcon icon="delete"/>
            </IconButton>
          </CardActionIcons>
        </CardActions>
      </Card>
    );
  }
}

ProjectCard.propTypes = {
  project: PropTypes.instanceOf(Project),
  setParrentUUID: PropTypes.func,
  dataContainer: PropTypes.instanceOf(DataContainer),

};
