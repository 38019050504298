
const serverConfig = {
  domain: 'hephaestus.ralphbisschops.com',
  environment: 'production',
  blldomain: 'hephaestus.ralphbisschops.com:8081',
  oauthdomain: 'hephaestus.ralphbisschops.com:8180',
  oauth: {
    userUUID: '55f68cbb-3583-4303-8b22-a56822f45ff3',
    storage: {
      serviceUUID: '8d4e8b39-445a-4c14-884c-3be8958441e0',
      redirectURI: 'https://hephaestus.ralphbisschops.com/permissions/code',
    },
    google: {
      serviceUUID: '0a7fd770-7e80-4e3a-84e0-a0a5a8323720',
      redirectURI: 'https://hephaestus.ralphbisschops.com/google_permissions/code',
    },
  },
};

export default serverConfig;
