
import InboxItem from './InboxItem';
import Inbox from './Inbox';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: InboxItem,
      list: Inbox,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'Inbox',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/inbox`,
        getLink:    `https://${serverConfig.blldomain}/api/inbox/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/inbox`,
      },
    };
  }

}
