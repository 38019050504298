import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import TaskList from './components/TaskList';
import TaskCards from './components/TaskCards';
import NoteCards from './components/NoteCards';
import NodeCards from './components/NodeCards';
import ProjectCards from './components/ProjectCards';
import DueCards from './components/DueCards';
import Navigation from './components/Navigation';
import InboxCards from './components/InboxCards';
import StarredCards from './components/StarredCards';
import EventTimerCards from './components/EventTimerCards';
import Dialogbox from './components/Dialogbox';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Permission from './components/Permission';
import GooglePermission from './components/GooglePermission';

import DataContainer from './modules/DataContainer';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      session: localStorage.getItem('sessionToken'),
    };

    this.state.dataContainer = new DataContainer(this.changeData.bind(this));
    if(this.state.session && window.location.pathname !== '/permissions/code' && window.location.pathname !== '/google_permissions/code'){
      this.state.dataContainer.setSessionToken(this.state.session);
      this.requestData();
    }
  }

  componentDidMount(){
    if(this.state.session){
      this.changeData();
    }
  }

  requestData(){
    this.state.dataContainer.requestAll();
  }

  changeData(){
    var oldData = this.state.dataContainer;
    this.setState({dataContainer: oldData});
    //console.log('React Data Changed');
  }

  setLoginFunc(sessionToken){
    this.setState({session:sessionToken});
    localStorage.setItem('sessionToken', sessionToken);
    if(sessionToken){
      this.state.dataContainer.setSessionToken(sessionToken);
      this.requestData();
      this.changeData();
    }
  }

  render() {
    if(window.location.pathname === '/permissions/code'){
      return (<Permission dataContainer={this.state.dataContainer} />);
    }
    if(window.location.pathname === '/google_permissions/code'){
      return (<GooglePermission dataContainer={this.state.dataContainer} />);
    }

    if(!this.state.session){
      return (
        <Login setLoginFunc={this.setLoginFunc.bind(this)}/>
      );
    }

    return (
      <BrowserRouter>
        <div>
          <Navigation setLoginFunc={this.setLoginFunc.bind(this)} dataContainer={this.state.dataContainer}>
            <Switch>
              <Route path='/' exact>
                <Dashboard dataContainer={this.state.dataContainer}/>
              </Route>
              <Route path='/tasks'>
                <TaskList dataContainer={this.state.dataContainer} parrentUUID={null}/>
              </Route>
              <Route path='/tasks_cards'>
                <TaskCards dataContainer={this.state.dataContainer} parrentUUID={null}/>
              </Route>
              <Route path='/inbox'>
                <InboxCards dataContainer={this.state.dataContainer}/>
              </Route>
              <Route path='/starred'>
                <StarredCards dataContainer={this.state.dataContainer}/>
              </Route>
              <Route path='/eventtimers'>
                <EventTimerCards dataContainer={this.state.dataContainer}/>
              </Route>
              <Route path='/notes'>
                <NoteCards dataContainer={this.state.dataContainer} parrentUUID={null}/>
              </Route>
              <Route path='/due'>
                <DueCards dataContainer={this.state.dataContainer}/>
              </Route>
              <Route path='/nodes/:nodeuuid?' render={(props) => <NodeCards {...props} dataContainer={this.state.dataContainer} parrentUUID={null}/>}/>
              <Route path='/projects'>
                <ProjectCards dataContainer={this.state.dataContainer}/>
              </Route>
              <Route path='/permissions' render={(props) => <Permission {...props} dataContainer={this.state.dataContainer} />}/>
              <Route path='/google_permissions' render={(props) => <GooglePermission {...props} dataContainer={this.state.dataContainer} />}/>
            </Switch>
          </Navigation>

          <Dialogbox/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
