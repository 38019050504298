// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Chart from 'chart.js';

// Material Design
import Card from '@material/react-card';

// Other Components

// Hepheastus
import DataContainer from '../../modules/DataContainer';

export default class DElementCount extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.labels = ['Inbox','Tasks','Notes','Projects'];
    this.state = {
      data: [
        this.dataContainer.getAll('Inbox'),
        this.dataContainer.getAll('Tasks'),
        this.dataContainer.getAll('Notes'),
        this.dataContainer.getAll('Projects'),
      ],
    };
    this.ref = React.createRef();
    this.dataChangedListener = this.dataChange.bind(this);
  }

  getAmountOfElements(){
    var dataList = [];
    for(var i in this.state.data){
      if(this.state.data[i] == null){
        dataList.push(0);
        continue;
      }
      dataList.push(this.state.data[i].length());
    }
    return dataList;
  }

  dataChange(e){
    if(['Inbox','Tasks','Notes','Projects'].includes(e.detail.datatype)){
      if(this.chart){
        var index = this.labels.indexOf(e.detail.datatype);
        this.chart.data.datasets[0].data[index] = this.state.data[index].length();
        this.chart.update();
      }
    }
  }

  componentDidMount(){
    var ctx = this.ref.current;
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          label: '# of Elements',
          data: this.getAmountOfElements(),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1,
        }],
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero:true,
            },
          }],
        },
      },
    });
    window.addEventListener('dataContainerChange', this.dataChangedListener);
  }

  componentWillUnmount(){
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
  }

  render(){
    return (
      <Card>
        <canvas ref={this.ref} width="400" height="400"></canvas>
      </Card>
    );
  }
}

DElementCount.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
