// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import {MDCDialog} from '@material/dialog';
import TextField, {Input} from '@material/react-text-field';
//import MaterialIcon from '@material/react-material-icon';
import Button from '@material/react-button';
import {Cell, Grid, Row} from '@material/react-layout-grid';

// Other Components
import Dialogbox from '../Dialogbox';

// Hepheastus
import {EventTimer} from '../../modules/EventTimers';
import DataContainer from '../../modules/DataContainer';


export default class EventTimerEditDialog extends Component {

  constructor(props){
    super(props);
    this.state = {
      eventTimer: props.eventTimer,
    };
    this.dataContainer = props.dataContainer;
    this.dialog = null;
    this.closeDialogListener = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.dialog = new MDCDialog(document.querySelector('.mdc-dialog.editEventTimerDialog'));
    this.dialog.open();
    this.dialog.listen('MDCDialog:closed', this.closeDialogListener);
  }

  componentWillUnmount() {
    if(this.dialog){
      this.dialog.unlisten('MDCDialog:closed',this.closeDialogListener);
      this.dialog = null;
    }
  }

  closeDialog(event){
    if(event.detail.action === 'save') {
      this.saveEventTimer();
    }
    ReactDOM.unmountComponentAtNode(Dialogbox.getRef().current);
  }

  async saveEventTimer(){
    if(this.state.eventTimer.links.self){
      this.state.eventTimer.update(this.dataContainer.sessionToken,
        ['startDate']);
    }else{
      this.dataContainer.add('EventTimer',this.state.eventTimer);
      this.state.eventTimer.insert(this.dataContainer.sessionToken);
    }
    this.dataContainer.changeFunc();
  }

  changeEventTimerData(name, value){
    var eventTimer = this.state.eventTimer;
    eventTimer[name] = value;
    this.setState({
      eventTimer: eventTimer,
    });
  }

  formatDate(d) {
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
  }

  formatTime(d) {
    var hh = '' + d.getHours();
    var mm = '' + d.getMinutes();

    if (hh.length < 2) hh = '0' + hh;
    if (mm.length < 2) mm = '0' + mm;

    return `${hh}:${mm}`;
  }

  render(){
    var startDateObj = new Date(this.state.eventTimer.startDate);
    var startDate = {
      time: this.formatTime(startDateObj),
      date: this.formatDate(startDateObj),
    };
    if(this.state.eventTimer.startDate === null){
      startDate = {time: '', date: ''};
    }
    return (
      <div className="mdc-dialog editEventTimerDialog">
        <div className="mdc-dialog__container">
          <div className="mdc-dialog__surface">
            <Grid>
              <Row>
                <Cell desktopColumns={6} phoneColumns={2}>
                  <TextField label='Start Date' >
                    <Input
                      size="20"
                      type='date'
                      value={startDate.date}
                      onChange={(e) => {
                        if(!isNaN(Date.parse(e.target.value))){
                          var newDate = new Date(e.target.value);
                          var date = new Date();
                          if(this.state.eventTimer.startDate !== null){
                            date = new Date(this.state.eventTimer.startDate);
                          }
                          date.setFullYear(newDate.getFullYear());
                          date.setMonth(newDate.getMonth());
                          date.setDate(newDate.getDate());
                          this.changeEventTimerData('startDate', date.toISOString());
                        }
                        if(e.target.value === ''){
                          this.changeEventTimerData('startDate', null);
                        }
                      }}
                    />
                  </TextField>
                </Cell>
                <Cell desktopColumns={6} phoneColumns={2}>
                  <TextField label='Start Date' >
                    <Input
                      size="20"
                      type='time'
                      value={startDate.time}
                      onChange={(e) => {
                        var now = new Date();
                        if(!isNaN(Date.parse(this.formatDate(now) + ' '+ e.target.value))){
                          var newDate = new Date(this.formatDate(now) + ' '+ e.target.value);
                          var date = new Date();
                          if(this.state.eventTimer.startDate !== null){
                            date = new Date(this.state.eventTimer.startDate);
                          }
                          date.setHours(newDate.getHours());
                          date.setMinutes(newDate.getMinutes());
                          date.setSeconds(newDate.getSeconds());
                          date.setMilliseconds(newDate.getMilliseconds());
                          this.changeEventTimerData('startDate', date.toISOString());
                        }
                        if(e.target.value === ''){
                          this.changeEventTimerData('startDate', null);
                        }
                      }}
                    />
                  </TextField>
                </Cell>
              </Row>
            </Grid>
            <footer className="mdc-dialog__actions">
              <Button data-mdc-dialog-action="close">Cancel</Button>
              <Button  data-mdc-dialog-action="save">Save</Button>
            </footer>
          </div>
        </div>
        <div className="mdc-dialog__scrim"></div>
      </div>
    );
  }
}

EventTimerEditDialog.propTypes = {
  eventTimer: PropTypes.instanceOf(EventTimer),
  dataContainer: PropTypes.instanceOf(DataContainer),

};
