// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import {MDCDialog} from '@material/dialog';
import MaterialIcon from '@material/react-material-icon';
import Button from '@material/react-button';

// Other Components
import Dialogbox from '../Dialogbox';

// Hepheastus
import {Project} from '../../modules/Projects';
import DataContainer from '../../modules/DataContainer';


export default class ProjectDeleteDialog extends Component {

  constructor(props){
    super(props);
    this.state = {
      project: props.project,
    };
    this.dataContainer = props.dataContainer;
    this.dialog = null;
    this.closeDialogListener = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.dialog = new MDCDialog(document.querySelector('.mdc-dialog.deleteProjectDialog'));
    this.dialog.open();
    this.dialog.listen('MDCDialog:closed', this.closeDialogListener );
  }

  closeDialog(event){
    if(event.detail.action === 'delete') {
      this.state.project.delete(this.dataContainer.sessionToken);
      var node = this.dataContainer.get('Nodes','nodeUUID',this.state.project.nodeUUID);
      var inbox = this.dataContainer.get('Inbox','nodeUUID',this.state.project.nodeUUID);
      this.dataContainer.remove('Projects',this.state.project);
      this.dataContainer.remove('Nodes',node);
      if(inbox){
        this.dataContainer.remove('Inbox',inbox);
      }
    }
    this.dataContainer.changeFunc();
    ReactDOM.unmountComponentAtNode(Dialogbox.getRef().current);
  }

  componentWillUnmount() {
    if(this.dialog){
      this.dialog.unlisten('MDCDialog:closed',this.closeDialogListener);
      this.dialog = null;
    }
  }

  render(){
    return (
      <div className="mdc-dialog deleteProjectDialog">
        <div className="mdc-dialog__container">
          <div className="mdc-dialog__surface">
            <h2 className="mdc-dialog__title">
              Delete this Project?
            </h2>
            <div className="mdc-dialog__content">
              Delete the project: &#39;{this.state.project.name}&#39;?

            </div>
            <footer className="mdc-dialog__actions">
              <Button data-mdc-dialog-action="close">Cancel</Button>
              <Button data-mdc-dialog-action="delete" icon={<MaterialIcon icon='delete'/>}>Delete</Button>
            </footer>
          </div>
        </div>
        <div className="mdc-dialog__scrim"></div>
      </div>
    );
  }
}

ProjectDeleteDialog.propTypes = {
  project: PropTypes.instanceOf(Project),
  dataContainer: PropTypes.instanceOf(DataContainer),

};
