// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Redirect } from 'react-router-dom';

// Material Design
import TopAppBar, {
  TopAppBarIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import Drawer, { DrawerAppContent } from '@material/react-drawer';
import {ListItemText, ListItemGraphic} from '@material/react-list';
import Fab from '@material/react-fab';

// Other Components
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import DataContainer from '../../modules/DataContainer';

class Navigation extends Component {

  state = {
    open: true,
    menu: false,
    anchorElement: null,
    redirect: null,
    online: true,
    drawerItems: [],
  };

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.currentpage = '';
    this.setLoginFunc = this.props.setLoginFunc;
    if(typeof window.orientation !== 'undefined'){
      // is mobile
      this.state.open = false;
    }
    this.state.drawerItems = this.getDrawerItems();
    this.dataChangedListener = this.dataChange.bind(this);
  }

  getDrawerItems(){
    return [
      {
        name: 'Dashboard',
        icon: 'dashboard',
        path: '/',
      },{
        name: 'Inbox',
        icon: 'inbox',
        path: '/inbox',
        count: this.dataContainer.getAll('Inbox'),
      },{
        name: 'Starred',
        icon: 'star',
        path: '/starred',
        count: this.dataContainer.getAll('Starred'),
      },{
        name: 'Timers',
        icon: 'timer',
        path: '/eventtimers',
        count: this.dataContainer.getAll('EventTimers','endDate',null),
      },{
        name: 'Nodes',
        icon: 'playlist_add_check',
        path: '/nodes',
      },{
        name: 'Due',
        icon: 'timelapse',
        path: '/due',
      },{
        name: 'Tasks',
        icon: 'playlist_add_check',
        path: '/tasks',
      },{
        name: 'Notes',
        icon: 'note',
        path: '/notes',
      },{
        name: 'Projects',
        icon: 'insert_drive_file',
        path: '/projects',
      },{
        name: 'Permissions',
        icon: 'build',
        path: '/permissions',
      },{
        name: 'Google Permissions',
        icon: 'build',
        path: '/google_permissions',
      },
    ];
  }

  setOnline(){
    this.setState({online: navigator.onLine});
  }

  componentDidMount(){
    window.addEventListener('online',  this.setOnline.bind(this));
    window.addEventListener('offline', this.setOnline.bind(this));
    window.addEventListener('dataContainerChange', this.dataChangedListener);
  }

  componentWillUnmount(){
    window.removeEventListener('online',  this.setOnline.bind(this));
    window.removeEventListener('offline', this.setOnline.bind(this));
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
  }


  setRedirect = (path) => {
    this.setState({redirect: path});
  }

  setAnchorElement = (element) => {
    if (this.state.anchorElement) {
      return;
    }
    this.setState({anchorElement: element});
  }

  async startSync(){
    Snackbarbox.show({
      message: 'Sync Started',
      timeout: 1000,
    });
    var message = await this.dataContainer.doSync();
    Snackbarbox.show({
      message: message,
    });
  }

  dataChange(e){
    if(['Inbox','Starred','EventTimers'].includes(e.detail.datatype)){
      this.setState({drawerItems: this.getDrawerItems()});
    }
  }

  render(){
    if (this.state.redirect) {
      this.setState({redirect: null});
      return <Redirect to={this.state.redirect} />;
    }
    var onlineIndicator = (<MaterialIcon aria-label='online' key='online' icon='cloud_queue' onClick={() => {this.startSync();}}/>);
    if(!this.state.online){
      onlineIndicator = (<MaterialIcon aria-label='offline' key='online' icon='cloud_off' />);
    }
    onlineIndicator = (
      <TopAppBarIcon actionItem tabIndex={0}>
        {onlineIndicator}
      </TopAppBarIcon>
    );
    return (
      <div>
        <TopAppBar>
          <TopAppBarRow>
            <TopAppBarSection align='start'>
              <TopAppBarIcon navIcon tabIndex={0}>
                <MaterialIcon
                  icon='menu'
                  onClick={() => this.setState({open: !this.state.open})}
                />
              </TopAppBarIcon>
              <TopAppBarTitle>Hephaestus</TopAppBarTitle>
            </TopAppBarSection>
            <TopAppBarSection align='end' role='toolbar'>
              <TopAppBarIcon actionItem tabIndex={0}>
                <MaterialIcon
                  aria-label='inbox'
                  key='inbox'
                  icon='inbox'
                  onClick={(e) => {e.stopPropagation();this.setRedirect('/inbox');}}
                />
              </TopAppBarIcon>
              {onlineIndicator}
              <TopAppBarIcon actionItem tabIndex={0}>
                <MaterialIcon
                  aria-label='logout'
                  key='logout'
                  icon='lock'
                  onClick={(e) => {e.stopPropagation();this.setLoginFunc(null);}}
                />
              </TopAppBarIcon>
            </TopAppBarSection>
          </TopAppBarRow>
        </TopAppBar>
        <Drawer
          dismissible
          open={true}
          className={`mdc-top-app-bar--fixed-adjust ${this.state.open?'':'drawerCollapse'}`}
        >
          {this.state.open ? (
            <nav className='mdc-list'>
              {this.state.drawerItems.map(item => (
                <NavLink key={item.name} className="mdc-list-item" activeClassName='mdc-list-item--activated' to={item.path} exact>
                  <ListItemGraphic graphic={<MaterialIcon icon={item.icon}/>} />
                  <ListItemText primaryText={item.name + (item.count?(` (${item.count.length()})`):'')}/>
                </NavLink>
              ))}
            </nav>
          ) : (
            <nav className='fab-list'>
              {this.state.drawerItems.map(item => (
                <NavLink key={item.name} className="fab-list-item" activeClassName='fab-list-item--activated' to={item.path} exact>
                  <Fab icon={<MaterialIcon icon={item.icon}/>} mini/>
                </NavLink>
              ))}
            </nav>
          )}
        </Drawer>
        <DrawerAppContent
          className={`mdc-top-app-bar--fixed-adjust ${this.state.open?'':'drawerCollapsed'}`}
        >
          {this.props.children}
          <Snackbarbox/>
        </DrawerAppContent>
      </div>
    );
  }
}

Navigation.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  setLoginFunc: PropTypes.func,
};

export default Navigation;
