import {API_Element_Object} from '../API_Object';
import Joi from '@hapi/joi';

import ModuleProperties from './ModuleProperties';

export default class CountTelemetryItem extends API_Element_Object {

  constructor(data = null){
    super(data);
    this.type = 'counttelemetryitem';
    this.keyPropertie = 'date';
    this.validator = this.constructor.validator();
    this.insertProperties = [];
    this.updateProperties = [];
    this.storeProperties = ['type','date','countType','count','links'];
    this.useIndexDB = false;
    if(data != null){
      this.parseData(data);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
    this.key = new Date().toISOString();
    this.date = this.key;
    this.countType = '';
    this.count = 0;
  }

  static validator(){
    return {
      type: Joi.string().valid('counttelemetryitem').required(),
      date: Joi.date().iso().required(),
      countType: Joi.string().min(1).max(30).required(),
      count: Joi.number().min(0).max(4294967295).required(),
      links: super.validator().links,
    };
  }

  parseData(data){
    super.parseData(data);
    this.key = data.date;
    this.date = data.date;
    this.countType = data.countType;
    this.count = data.count;
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
