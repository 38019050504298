
import EventTimer from './EventTimer';
import EventTimers from './EventTimers';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: EventTimer,
      list: EventTimers,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'EventTimers',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/eventtimers`,
        getLink:    `https://${serverConfig.blldomain}/api/eventtimers/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/eventtimers`,
      },
    };
  }

}
