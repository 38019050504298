import loadedModules from '../loadedModules';

class IndexDB {

  constructor(){
    this.db = null;
  }

  async openIndexedDB(){
    this.db = await this.openIndexedDBInstance();
    return this.db;
  }

  async openIndexedDBInstance(){
    return new Promise(function(resolve, reject) {
      var request = window.indexedDB.open('Hephaestus');
      request.onerror = function(event) {
        alert('This app uses IndexedDB. Make sure this is enabled. (it is disabled right now)');
        reject(event);
      };
      request.onsuccess = function(event) {
        this.db = event.target.result;
        this.db.onerror = function(event) {
          console.log('Database error: ' + event.target.transaction.error);
          reject(event);
        };
        resolve(this.db);
      };
      request.onupgradeneeded = function(event) {
        // Save the IDBDatabase interface
        var db = event.target.result;

        // Create an objectStore(s) for this database
        for(var i in loadedModules.tables){
          var table = loadedModules.tables[i];
          var objectStore = db.createObjectStore(table.tableName, table.key);
          // Create Indexes on ObjectStore
          for(var j in table.indexes){
            var index = table.indexes[j];
            if(index.objectParameters){
              objectStore.createIndex(index.indexName, index.keyPath, index.objectParameters);
            }else{
              objectStore.createIndex(index.indexName, index.keyPath);
            }
          }
        }

      };
    });
  }


  clearIndexedDBInstance(){
    if(this.db){
      for(var i in loadedModules.tables){
        var table = loadedModules.tables[i];
        var transaction = this.db.transaction(table.tableName, 'readwrite');
        transaction.objectStore(table.tableName).clear();
        console.log('cleared', table.tableName);
      }
    }
  }

}

var indexDB = new IndexDB();
var db = indexDB.openIndexedDB();

export default db;
export {indexDB as IndexDB};
