// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import {MDCDialog} from '@material/dialog';
import TextField, {Input} from '@material/react-text-field';
//import MaterialIcon from '@material/react-material-icon';
import Button from '@material/react-button';
import Checkbox from '@material/react-checkbox';
import {Cell, Grid, Row} from '@material/react-layout-grid';

// Other Components
import Dialogbox from '../Dialogbox';

// Hepheastus
import {Node} from '../../modules/Nodes';
import {Task} from '../../modules/Tasks';
import {InboxItem} from '../../modules/Inbox';
import DataContainer from '../../modules/DataContainer';

export default class TaskEditDialog extends Component {

  constructor(props){
    super(props);
    this.state = {
      task: props.task,
    };
    this.dataContainer = props.dataContainer;
    this.inbox = props.inbox || false;
    this.dialog = null;
    this.closeDialogListener = this.closeDialog.bind(this);
  }

  componentDidMount() {
    this.dialog = new MDCDialog(document.querySelector('.mdc-dialog.editTaskDialog'));
    this.dialog.open();
    this.dialog.listen('MDCDialog:closed', this.closeDialogListener);
  }

  componentWillUnmount() {
    if(this.dialog){
      this.dialog.unlisten('MDCDialog:closed',this.closeDialogListener);
      this.dialog = null;
    }
  }

  closeDialog(event){
    if(event.detail.action === 'save') {
      if(this.state.task.name){
        this.saveTask();
      }
    }
    ReactDOM.unmountComponentAtNode(Dialogbox.getRef().current);
  }

  async saveTask(){
    if(this.state.task.links.self){
      this.state.task.update(this.dataContainer.sessionToken, ['name','status','dueDate','lastChangedDate']);
    }else{
      this.dataContainer.add('Tasks',this.state.task);
      // Add Node
      var node = new Node();
      node.key = this.state.task.nodeUUID;
      node.nodeUUID = this.state.task.nodeUUID;
      node.nodeType = 'Tasks';
      this.dataContainer.add('Nodes', node);
      // Add to Inbox (if requested)
      if(this.inbox){
        var inboxitem = new InboxItem();
        inboxitem.nodeUUID = this.state.task.nodeUUID;
        inboxitem.nodeType = this.state.task.type;
        inboxitem.includedElement = this.state.task;
        this.dataContainer.add('Inbox',inboxitem);
      }
      try{
        // Create Element
        var createdTask = await this.state.task.insert(this.dataContainer.sessionToken);
        // Update Related Elements
        node.key = createdTask.nodeUUID;
        node.nodeUUID = createdTask.nodeUUID;
        if(this.inbox){
          inboxitem.nodeUUID = createdTask.nodeUUID;
          inboxitem.insert(this.dataContainer.sessionToken);
        }
      }catch(e){
        throw e;
      }finally{
        // Node will only be added to IndexDB, can not be inserted to server.
        await node.insert(this.dataContainer.sessionToken);
      }
    }
    this.dataContainer.changeFunc();
  }

  changeTaskData(name, value){
    var task = this.state.task;
    task[name] = value;
    task.lastChangedDate = new Date().toISOString();
    this.setState({
      task: task,
    });
  }

  formatDate(d) {
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${year}-${month}-${day}`;
  }

  formatTime(d) {
    var hh = '' + d.getHours();
    var mm = '' + d.getMinutes();

    if (hh.length < 2) hh = '0' + hh;
    if (mm.length < 2) mm = '0' + mm;

    return `${hh}:${mm}`;
  }

  render(){
    var dueDateObj = new Date(this.state.task.dueDate);
    var dueDate = {
      time: this.formatTime(dueDateObj),
      date: this.formatDate(dueDateObj),
    };
    if(this.state.task.dueDate === null){
      dueDate = {time: '', date: ''};
    }
    return (
      <div className="mdc-dialog editTaskDialog">
        <div className="mdc-dialog__container">
          <div className="mdc-dialog__surface">
            <Grid>
              <Row>
                <Cell desktopColumns={1} phoneColumns={1}>
                  <Checkbox
                    nativeControlId={'checkbox-'+this.state.task.nodeUUID}
                    checked={this.state.task.status===1}
                    onChange={(e) => {
                      this.changeTaskData('status', e.target.checked?1:0);
                    }}
                  />
                </Cell>
                <Cell desktopColumns={11} phoneColumns={3}>
                  <TextField label='Name' >
                    <Input
                      size="40"
                      value={this.state.task.name}
                      onChange={(e) => {
                        this.changeTaskData('name', e.target.value);
                      }}
                    />
                  </TextField>
                </Cell>
              </Row>
              <Row>
                <Cell desktopColumns={6} phoneColumns={2}>
                  <TextField label='Due Date' >
                    <Input
                      size="20"
                      type='date'
                      value={dueDate.date}
                      onChange={(e) => {
                        if(!isNaN(Date.parse(e.target.value))){
                          var newDate = new Date(e.target.value);
                          var date = new Date();
                          if(this.state.task.dueDate !== null){
                            date = new Date(this.state.task.dueDate);
                          }
                          date.setFullYear(newDate.getFullYear());
                          date.setMonth(newDate.getMonth());
                          date.setDate(newDate.getDate());
                          this.changeTaskData('dueDate', date.toISOString());
                        }
                        if(e.target.value === ''){
                          this.changeTaskData('dueDate', null);
                        }
                      }}
                    />
                  </TextField>
                </Cell>
                <Cell desktopColumns={6} phoneColumns={2}>
                  <TextField label='Due Date' >
                    <Input
                      size="20"
                      type='time'
                      value={dueDate.time}
                      onChange={(e) => {
                        var now = new Date();
                        if(!isNaN(Date.parse(this.formatDate(now) + ' '+ e.target.value))){
                          var newDate = new Date(this.formatDate(now) + ' '+ e.target.value);
                          var date = new Date();
                          if(this.state.task.dueDate !== null){
                            date = new Date(this.state.task.dueDate);
                          }
                          date.setHours(newDate.getHours());
                          date.setMinutes(newDate.getMinutes());
                          date.setSeconds(newDate.getSeconds());
                          date.setMilliseconds(newDate.getMilliseconds());
                          this.changeTaskData('dueDate', date.toISOString());
                        }
                        if(e.target.value === ''){
                          this.changeTaskData('dueDate', null);
                        }
                      }}
                    />
                  </TextField>
                </Cell>
              </Row>
              <Row>
                <Cell desktopColumns={12}>
                  <p>
                    Created:&nbsp;
                    {this.dataContainer.formatDate(this.state.task.creationDate)}
                  </p>
                  <p>
                    Last Changed:&nbsp;
                    {this.dataContainer.formatDate(this.state.task.lastChangedDate)}
                  </p>
                </Cell>
              </Row>
            </Grid>
            <footer className="mdc-dialog__actions">
              <Button data-mdc-dialog-action="close">Cancel</Button>
              <Button  data-mdc-dialog-action="save">Save</Button>
            </footer>
          </div>
        </div>
        <div className="mdc-dialog__scrim"></div>
      </div>
    );
  }
}

TaskEditDialog.propTypes = {
  task: PropTypes.instanceOf(Task),
  inbox: PropTypes.bool,
  dataContainer: PropTypes.instanceOf(DataContainer),
};
