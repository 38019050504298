
import CountTelemetryItem from './CountTelemetryItem';
import CountTelemetry from './CountTelemetry';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: CountTelemetryItem,
      list: CountTelemetry,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: '',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/counttelemetry`,
        getLink:    `https://${serverConfig.blldomain}/api/counttelemetry/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/counttelemetry`,
      },
    };
  }

}
