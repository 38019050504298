
// Supported Types
import {Note} from '../Notes';
import {Task} from '../Tasks';
import {Project} from '../Projects';

export default class NodeTools{

  static createNodeByType(data, nodeType){
    switch (nodeType) {
      case 'task':
        return new Task(data);
      case 'note':
        return new Note(data);
      case 'project':
        return new Project(data);
      default:
        return null;
    }
  }

}
