import {API_List_Object} from '../API_Object';
import Joi from '@hapi/joi';

import StarredItem from './StarredItem';
import ModuleProperties from './ModuleProperties';

export default class Starred extends API_List_Object {

  constructor(data = null, included = null){
    super(data);
    this.type = 'starred';
    this.keyPropertie = null;
    this.listElement = 'items';
    this.validator = this.constructor.validator();
    if(included == null){
      included = [];
    }
    if(data != null){
      this.parseData(data);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    super.fillDefault();
  }

  static validator(){
    return {
      type: Joi.string().valid('starred').required(),
      items: Joi.array().items(Joi.object(StarredItem.validator())),
      links: super.validator().links,
    };
  }

  parseData(data){
    super.parseData(data);
    this.key = null;
    this.items = data.items.map(element => {
      return new StarredItem(element);
    });
  }

  static indexDBInfo(){
    return ModuleProperties.getInfo().indexDBInfo;
  }

  static thisAPI(){
    return ModuleProperties.getInfo().APIInfo;
  }

  static thisObject(){
    return ModuleProperties.getObjects();
  }

}
