// React
import React, { Component } from 'react';
// import PropTypes from 'prop-types';

// Material Design
import {MDCSnackbar} from '@material/snackbar';

// Other Components

// Hepheastus


export default class Snackbarbox extends Component {

  static ref = React.createRef();
  static snackbar = null;
  static fab = null;
  static timer = null;
  static message = '';
  static actionText = '';
  static actionListener = () => {};

  // constructor(props){
  //   super(props);
  // }

  static getRef(){
    return this.ref;
  }

  static actionEventHandler(event){
    if(event.detail.reason === 'action') {
      // Clicked on button
      if(typeof Snackbarbox.actionListener === 'function'){
        Snackbarbox.actionListener();
      }
    }else if(event.detail.reason === 'dismiss'){
      // Nothing done, timeout
    }else{
      // Other cases
    }
    Snackbarbox.actionListener = () => {};
  }

  static show(dataObject){
    // Change default (2750)
    dataObject.timeout = dataObject.timeout?dataObject.timeout:4000;
    if(dataObject.timeout > 10000){
      dataObject.timeout = 10000;
    }else if(dataObject.timeout < 4000){
      dataObject.timeout = 4000;
    }
    Snackbarbox.message = dataObject.message;
    Snackbarbox.actionText = dataObject.actionText;
    Snackbarbox.actionListener = dataObject.actionHandler;
    this.snackbar.labelText = dataObject.message;
    this.snackbar.actionButtonText = dataObject.actionText;
    this.snackbar.timeoutMs = dataObject.timeout;
    this.snackbar.open();
    // this.timer = setTimeout(() => {
    //
    //   // this.snackbar.show({
    //   //   message: '.',
    //   //   timeout: 0,
    //   // });
    // },dataObject.timeout+5);
  }

  static mountComponent(){
    if(!this.snackbar){
      this.snackbar = new MDCSnackbar(document.querySelector('.mdc-snackbar.defaultSnackbar'));
      this.snackbar.listen('MDCSnackbar:closing', Snackbarbox.actionEventHandler);
    }
  }

  static removeComponent(){
    if(this.snackbar){
      this.snackbar.unlisten('MDCSnackbar:closing', Snackbarbox.actionEventHandler);
      //this.snackbar = null;
    }
  }

  static setFAB(value){
    this.fab = value;
  }

  componentDidMount() {
    this.constructor.mountComponent();
  }

  componentWillUnmount() {
    this.constructor.removeComponent();
  }

  render(){
    // console.log(Snackbarbox);
    return (
      <div id='snackbarbox' ref={Snackbarbox.getRef()}>
        <div className={(this.fab?'':'snackbarwithFab ')+'mdc-snackbar defaultSnackbar'}
          aria-live="assertive"
          aria-atomic="true"
          aria-hidden="true">
          <div className="mdc-snackbar__surface">
            <div className="mdc-snackbar__label">{Snackbarbox.message}</div>
            {/* TODO update is not rendered, button is still visible */}
            {(this.actionText!=='' || this.actionText!==undefined) &&
              <div className="mdc-snackbar__actions">
                <button type="button" className="mdc-snackbar__action mdc-button">{Snackbarbox.actionText}</button>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

Snackbarbox.propTypes = {

};
