// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Card, {
  CardPrimaryContent,
  CardActions,
  CardActionIcons,
} from '@material/react-card';
import MaterialIcon from '@material/react-material-icon';
import IconButton from '@material/react-icon-button';

// Other Component
import NoteEditDialog from '../NoteEditDialog';
import NoteDeleteDialog from '../NoteDeleteDialog';
import Dialogbox from '../Dialogbox';
import sanitizeHtml from 'sanitize-html';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Note} from '../../modules/Notes';
import {EventTimer} from '../../modules/EventTimers';
import {Event} from '../../modules/Events';
import {StarredItem} from '../../modules/Starred';
import DataContainer from '../../modules/DataContainer';

export default class NoteCard extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      note: props.note,
      starred: this.dataContainer.get('Starred','nodeUUID',props.note.nodeUUID),
    };
    this.markdownConverter = props.markdownConverter;
    this.setParrentUUID = props.setParrentUUID;
    this.enableTimerStart = true;//Used for delay on dubbelclick
    this.dataChangedListener = this.dataChange.bind(this);
  }

  dataChange(e){
    if(e.detail.datatype === 'Starred'){
      this.setState({starred: this.dataContainer.get('Starred','nodeUUID',this.state.note.nodeUUID)});
    }
  }

  componentDidMount(){
    window.addEventListener('dataContainerChange', this.dataChangedListener);
  }

  componentWillUnmount(){
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
  }

  renderMarkdown(){
    // SECURITY: Injecting HTML is dangerous!
    var sanitize = sanitizeHtml(this.state.note.message,{allowedTags: [],allowedAttributes: []});
    return {__html: this.markdownConverter.makeHtml(sanitize)};
  }

  saveNote(){
    if(this.state.note.links.self){
      this.state.note.update(this.dataContainer.sessionToken, ['message','lastChangedDate']);
    }else{
      this.state.note.insert(this.dataContainer.sessionToken);
      this.dataContainer.add('Notes',this.state.note);
    }
  }

  changeNoteData(name, value){
    var note = this.state.note;
    note[name] = value;
    note.lastChangedDate = new Date().toISOString();
    this.setState({
      note: note,
    });
  }

  static openEditNoteDialog(note,dataContainer){
    var notedialog = <NoteEditDialog note={note} dataContainer={dataContainer}/>;
    ReactDOM.render(notedialog, Dialogbox.getRef().current);
  }

  static openDeleteNoteDialog(note,dataContainer){
    var notedialog = <NoteDeleteDialog note={note} dataContainer={dataContainer}/>;
    ReactDOM.render(notedialog, Dialogbox.getRef().current);
  }

  async startTimer(){
    Snackbarbox.show({
      message: 'Timer started',
    });
    var timer = new EventTimer();
    await timer.startTimer(this.dataContainer.sessionToken, this.state.note.nodeUUID);
    this.dataContainer.add('EventTimers',timer);
    if(timer.includedElement && timer.includedElement instanceof Event){
      this.dataContainer.add('Events',timer.includedElement);
    }
  }

  async starItem(item, showAction = true){
    if(showAction){
      Snackbarbox.show({
        message: 'Starred',
        actionHandler: () => {this.unstarItem(item, false);},
        actionText: 'undo',
      });
    }else{
      Snackbarbox.show({
        message: 'Starred',
      });
    }
    var starreditem = new StarredItem();
    starreditem.nodeUUID = item.nodeUUID;
    starreditem.nodeType = item.type;
    starreditem.includedElement = item;
    this.dataContainer.add('Starred',starreditem);
    starreditem.insert(this.dataContainer.sessionToken);
  }

  async unstarItem(item, showAction = true){
    if(showAction){
      Snackbarbox.show({
        message: 'Removed from starred',
        actionHandler: () => {this.starItem(item, false);},
        actionText: 'undo',
      });
    }else{
      Snackbarbox.show({
        message: 'Removed from starred',
      });
    }
    var starreditem = this.dataContainer.get('Starred','nodeUUID',item.nodeUUID);
    this.dataContainer.remove('Starred',starreditem);
    starreditem.delete(this.dataContainer.sessionToken);
  }

  render(){
    return (
      <Card>
        <CardPrimaryContent>
          <div className='noteContent' dangerouslySetInnerHTML={this.renderMarkdown()}></div>
        </CardPrimaryContent>
        <CardActions>
          <CardActionIcons>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              this.setParrentUUID(this.state.project.nodeUUID);
            }}>
              <MaterialIcon icon="subdirectory_arrow_right"/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              if(this.state.starred){
                this.unstarItem(this.state.note);
              }else{
                this.starItem(this.state.note);
              }
            }}>
              <MaterialIcon icon={this.state.starred?'star':'star_border'}/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              if(this.enableTimerStart){
                this.enableTimerStart = false;
                setTimeout(() => {this.enableTimerStart = true;},2000);
                this.startTimer();
              }
            }}>
              <MaterialIcon icon="play_arrow"/>
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                NoteCard.openEditNoteDialog(this.state.note,this.dataContainer);
              }}>
              <MaterialIcon icon="edit"/>
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                NoteCard.openDeleteNoteDialog(this.state.note,this.dataContainer);
              }}>
              <MaterialIcon icon="delete"/>
            </IconButton>
          </CardActionIcons>
        </CardActions>
      </Card>
    );
  }
}

NoteCard.propTypes = {
  note: PropTypes.instanceOf(Note),
  dataContainer: PropTypes.instanceOf(DataContainer),
  markdownConverter: PropTypes.instanceOf(Object),
  setParrentUUID: PropTypes.func,
};
