// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Material Design
import Card, {
  CardPrimaryContent,
  CardActions,
  CardActionIcons,
} from '@material/react-card';
import MaterialIcon from '@material/react-material-icon';
import IconButton from '@material/react-icon-button';
import Checkbox from '@material/react-checkbox';
import {Cell, Grid, Row} from '@material/react-layout-grid';

// Other Component
import TaskEditDialog from '../TaskEditDialog';
import TaskDeleteDialog from '../TaskDeleteDialog';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Task} from '../../modules/Tasks';
import DataContainer from '../../modules/DataContainer';
import {EventTimer} from '../../modules/EventTimers';
import {Event} from '../../modules/Events';
import {StarredItem} from '../../modules/Starred';


class TaskCard extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      task: props.task,
      starred: this.dataContainer.get('Starred','nodeUUID',props.task.nodeUUID),
    };
    this.setParrentUUID = props.setParrentUUID;
    this.enableTimerStart = true;//Used for delay on dubbelclick
    this.dataChangedListener = this.dataChange.bind(this);
  }

  dataChange(e){
    if(['Starred'].includes(e.detail.datatype)){
      this.setState({starred: this.dataContainer.get('Starred','nodeUUID',this.state.task.nodeUUID)});
    }
  }

  componentDidMount(){
    window.addEventListener('dataContainerChange', this.dataChangedListener);
  }

  componentWillUnmount(){
    window.removeEventListener('dataContainerChange', this.dataChangedListener);
  }

  changeTaskData(name, value){
    var task = this.state.task;
    task[name] = value;
    task.lastChangedDate = new Date().toISOString();
    this.setState({
      task: task,
    });
    this.saveTask();
  }

  saveTask(){
    if(this.state.task.links.self){
      this.state.task.update(this.dataContainer.sessionToken, ['status','lastChangedDate']);
    }
    this.dataContainer.changeFunc();
  }

  static openEditTaskDialog(task,dataContainer){
    var taskdialog = <TaskEditDialog task={task} dataContainer={dataContainer}/>;
    ReactDOM.render(taskdialog, Dialogbox.getRef().current);
  }

  static openDeleteTaskDialog(task,dataContainer){
    var taskdialog = <TaskDeleteDialog task={task} dataContainer={dataContainer}/>;
    ReactDOM.render(taskdialog, Dialogbox.getRef().current);
  }

  async startTimer(){
    Snackbarbox.show({
      message: 'Timer started',
    });
    var timer = new EventTimer();
    await timer.startTimer(this.dataContainer.sessionToken, this.state.task.nodeUUID);
    this.dataContainer.add('EventTimers',timer);
    if(timer.includedElement && timer.includedElement instanceof Event){
      this.dataContainer.add('Events',timer.includedElement);
    }
  }

  async starItem(item, showAction = true){
    if(showAction){
      Snackbarbox.show({
        message: 'Starred',
        actionHandler: () => {this.unstarItem(item, false);},
        actionText: 'undo',
      });
    }else{
      Snackbarbox.show({
        message: 'Starred',
      });
    }
    var starreditem = new StarredItem();
    starreditem.nodeUUID = item.nodeUUID;
    starreditem.nodeType = item.type;
    starreditem.includedElement = item;
    this.dataContainer.add('Starred',starreditem);
    starreditem.insert(this.dataContainer.sessionToken);
  }

  async unstarItem(item, showAction = true){
    if(showAction){
      Snackbarbox.show({
        message: 'Removed from starred',
        actionHandler: () => {this.starItem(item, false);},
        actionText: 'undo',
      });
    }else{
      Snackbarbox.show({
        message: 'Removed from starred',
      });
    }
    var starreditem = this.dataContainer.get('Starred','nodeUUID',item.nodeUUID);
    this.dataContainer.remove('Starred',starreditem);
    starreditem.delete(this.dataContainer.sessionToken);
  }

  render(){
    var dueDate = '';
    if(this.state.task.dueDate){
      dueDate = new Date(this.state.task.dueDate);
      dueDate = this.dataContainer.formatDate(dueDate);
    }

    var tasklist = this.dataContainer.getAll('Tasks').getAll('parrentUUID', this.state.task.nodeUUID);
    var completedSubtasks = 0;
    var totalSubtasks = tasklist.items.length;
    for(var i in tasklist.items){
      if(tasklist.items[i].status === 1){
        completedSubtasks++;
      }
    }

    return (
      <Card>
        <CardPrimaryContent>
          <Grid>
            <Row>
              <Cell columns={2}>
                <Checkbox
                  nativeControlId={'checkbox-'+this.state.task.nodeUUID}
                  checked={this.state.task.status===1}
                  onChange={(e) => {
                    console.log('checkbox');
                    this.changeTaskData('status', e.target.checked?1:0);
                  }}
                  onClick={(e)=>{
                    e.stopPropagation();
                  }}
                />
              </Cell>
              <Cell columns={10} className='centerField'>
                <p>{this.state.task.name}</p>
              </Cell>
            </Row>
            <Row>
              <Cell columns={10}>
                <p>
                  Subtasks: {completedSubtasks}/{totalSubtasks}<br/>
                  Due: {dueDate}
                </p>
              </Cell>
            </Row>
          </Grid>
        </CardPrimaryContent>
        <CardActions>
          <CardActionIcons>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              this.setParrentUUID(this.state.task.nodeUUID);
            }}>
              <MaterialIcon icon="subdirectory_arrow_right"/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              if(this.state.starred){
                this.unstarItem(this.state.task);
              }else{
                this.starItem(this.state.task);
              }
            }}>
              <MaterialIcon icon={this.state.starred?'star':'star_border'}/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              if(this.enableTimerStart){
                this.enableTimerStart = false;
                setTimeout(() => {this.enableTimerStart = true;},2000);
                this.startTimer();
              }
            }}>
              <MaterialIcon icon="play_arrow"/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              TaskCard.openEditTaskDialog(this.state.task,this.dataContainer);
            }}>
              <MaterialIcon icon="edit"/>
            </IconButton>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              TaskCard.openDeleteTaskDialog(this.state.task,this.dataContainer);
            }}>
              <MaterialIcon icon="delete"/>
            </IconButton>
          </CardActionIcons>
        </CardActions>
      </Card>
    );
  }
}

TaskCard.propTypes = {
  task: PropTypes.instanceOf(Task),
  setParrentUUID: PropTypes.func,
  dataContainer: PropTypes.instanceOf(DataContainer),
};


export default TaskCard;
