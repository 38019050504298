
import Task from './Task';
import Tasks from './Tasks';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: Task,
      list: Tasks,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'Tasks',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/tasks`,
        getLink:    `https://${serverConfig.blldomain}/api/tasks/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/tasks`,
      },
    };
  }

}
