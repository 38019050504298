import Joi from '@hapi/joi';
import indexDB, {IndexDB} from './IndexDB';

export default class API_Object {

  constructor(data = null){
    this.key = null;
    this.type = 'API_Object';
    this.keyPropertie = null;
    this.validator = this.constructor.validator();
    this.useIndexDB = true;
    this.insertProperties = [];
    this.updateProperties = [];
    this.storeProperties = [];
    if(data != null){
      this.parseDefaultData(data);
    }else{
      this.fillDefault();
    }
  }

  fillDefault(){
    this.key = null;
    this.links = {
      self: null,
    };
  }

  indexDBData(){
    return [];
  }

  static indexDBInfo(){
    return {
      table: '',
    };
  }

  static thisAPI(){
    return {
      listLink: '',
      getLink: '',
      insertLink: '',
    };
  }

  static thisObject(){
    return {
      element: API_Object,
      list: API_Object,
    };
  }

  static validator(){
    return {
      type: Joi.string().required(),
      links: Joi.object({
        self: Joi.string().allow(null).uri({scheme: ['http','https']}).required(),
        related: Joi.string().allow(null).uri({scheme: ['http','https']}).optional(),
      }),
    };
  }

  parseDefaultData(data){
    this.validateData(data, true);
    if(this.keyPropertie){
      this.key = data[this.keyPropertie];
    }

    if(data.links){
      this.links = {
        self: data.links.self,
      };
    }
  }

  parseData(data){
    this.parseDefaultData(data);
    this.validateData(data);
  }

  parseFromIndexDB(data){
    // Validation on items in list
    // See API_List_Object for implementation
  }

  validateData(data, allowUnknown = false){
    var options = {
      allowUnknown: allowUnknown,
      abortEarly: false,
    };
    const { error } = Joi.validate(data, this.validator, options);
    if (error) {
      console.log(error);
      throw error;
    }
  }

  static checkError(data){
    if(data.errors){
      throw new Error('Server Error: ' + data.errors[0].title);
    }
  }

  static async syncIndexDBandServer(token){
    //console.log('-- Start Sync--');
    var storedItems = await this.getAllFromIndexDBData();
    var classNameElement = this.thisObject().element;
    var elementsUpdated = 0;
    var error = null;
    // Check Updated Objects
    await Promise.all(storedItems.map(async function(object) {
      try{
        if(object.markedAsUpdated && object.links.self){
          delete object.markedAsUpdated;
          var element = new classNameElement(object);
          if(element.updateProperties.length !== 0){
            elementsUpdated++;
          }
          await element.update(token);
        }
      }catch(err){
        console.error('Error during Sync attempt');
        error = err;
      }
    }));
    // Check Deleted Objects
    await Promise.all(storedItems.map(async function(object) {
      try{
        if(object.markedAsDeleted && object.links.self){
          elementsUpdated++;
          delete object.markedAsDeleted;
          var element = new classNameElement(object);
          await element.delete(token);
        }
      }catch(err){
        console.error('Error during Sync attempt');
        error = err;
      }
    }));
    // Check Inserted Objects
    await Promise.all(storedItems.map(async function(object) {
      try{
        if(!object.links){
          throw Error('Add \'links\' to the array of \'storeProperties\' in '+ this.type);
        }
        if(!object.links.self && !object.markedAsDeleted){
          delete object.markedAsUpdated;
          var element = new classNameElement(object);
          if(element.insertProperties.length !== 0){
            elementsUpdated++;
          }
          await element.insert(token);
        }
      }catch(err){
        console.error('Error during Sync attempt');
        error = err;
        //console.error(err);
      }
    }));
    //console.log('-- End Sync--');
    if(error){
      throw error;
    }
    return elementsUpdated;
  }

  // Get Objects (List) from Server or IndexDB
  static list(token){
    var classObjectList = this;
    //console.log('list',classObjectList.indexDBInfo().table);
    if(classObjectList !== this.thisObject().list){
      console.log('The "list()" function can only be called from ' +
        this.thisObject().list.getClass());
      throw Error('Wrong list function call object.');
    }
    return new Promise(async function(resolve, reject) {
      var classNameList = classObjectList.thisObject().list;
      try{
        // Load List from Server
        var response = await fetch(classObjectList.thisAPI().listLink, {
          method: 'GET',
          headers: {
            'Authorization': token,
          },
        });
        var data = await response.json();
        API_Object.checkError(data);
        var list = new classNameList(data.data, data.included);
        // Update Stored Objects
        if(classObjectList.indexDBInfo().table){
          await list.updateInIndexDB(token);
        }
        //console.log('Downloaded '+ data.data.type);
        resolve(list);
      }catch(err){
        // Load List from IndexDB
        //console.log(err);
        if(classObjectList.indexDBInfo().table){
          var storedItems = await classObjectList.getAllFromIndexDBData();
          var storedList = new classNameList();
          //console.log('Loaded from IndexDB',classObjectList.indexDBInfo().table);
          storedList.parseFromIndexDB(storedItems);
          resolve(storedList);
        }else{
          reject(err);
        }
      }
    });
  }

  // Get Object (Element) from Server or IndexDB
  static get(token, id){
    var classObjectElement = this;
    if(classObjectElement !== this.thisObject().element){
      console.log('The "get()" function can only be called from ' +
        this.thisObject().element.getClass());
      throw Error('Wrong get function call object.');
    }
    return new Promise(async function(resolve, reject) {
      var classNameElement = classObjectElement.thisObject().element;
      try{
        // Load Element from Server
        var response = await fetch(
          classObjectElement.thisAPI().getLink.replace(/:id/, id), {
            method: 'GET',
            headers: {
              'Authorization': token,
            },
          });
        var resData = await response.json();
        API_Object.checkError(resData);
        var element = new classNameElement(resData.data);
        // Update Stored Element
        if(classObjectElement.indexDBInfo().table){
          await element.updateInIndexDB(token);
        }
        resolve(element);
      }catch(err){
        // Load Element from IndexDB
        //console.log(err);
        if(classObjectElement.indexDBInfo().table){
          var storedData = await classObjectElement.getFromIndexDBData(id);
          //console.log('Element loaded from IndexDB');
          var storedElement = new classNameElement(storedData);
          resolve(storedElement);
        }else{
          reject(err);
        }
      }
    });
  }

  async insert(token){
    var classObjectElement = this;
    if(classObjectElement.constructor !== this.constructor.thisObject().element){
      console.log('The "insert()" function can only be called from ' +
        this.constructor.thisObject().element.getClass());
      throw Error('Wrong insert function call object.');
    }
    // Item Does not exist in IndexDB or Server yet

    // Insert Object in IndexDB, Update Object if already exists
    var classNameElement = this.constructor.thisObject().element;
    var storedObject = new classNameElement();
    storedObject.key = classObjectElement.key;
    if(classObjectElement.constructor.indexDBInfo().table){
      await classObjectElement.updateInIndexDB(token);
    }
    return new Promise(async function(resolve, reject) {
      if(classObjectElement.insertProperties.length === 0){
        resolve(classObjectElement);
        return;
      }
      var data = classObjectElement.filterProperties(classObjectElement, classObjectElement.insertProperties);
      try{
        var response = await fetch(classObjectElement.constructor.thisAPI().insertLink, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': token,
          },
          body: JSON.stringify(data),
        });
        var resData = await response.json();
        // Object stored on Server
        API_Object.checkError(resData);
        classObjectElement.parseData(resData.data,resData.included);

        if(storedObject.key !== classObjectElement.key && classObjectElement.constructor.indexDBInfo().table){
          // Key was changed by Server
          // Remove old local copy
          API_Object.removeFromIndexDB(storedObject);
        }

        // Update IndexDB if data is stored different on Server (example other UUID)
        // Also store links.self
        if(classObjectElement.constructor.indexDBInfo().table){
          await classObjectElement.updateInIndexDB(token);
        }
        resolve(classObjectElement);
      }catch(err){
        reject(err);
      }
    });
  }

  async update(token, properties = [], storeItem = true){
    // If item doesn't exist on Server, use insert
    if(!this.links.self){
      return this.insert();
    }

    var classObjectElement = this;
    if(classObjectElement.constructor !== this.constructor.thisObject().element){
      console.log('The "update()" function can only be called from ' +
        this.constructor.thisObject().element.getClass());
      throw Error('Wrong update function call object.');
    }
    // Store task in IndexDB
    if(storeItem && classObjectElement.constructor.indexDBInfo().table){
      await classObjectElement.updateInIndexDB(token,true);
    }
    // Store task on Server
    return new Promise(async function(resolve, reject) {
      // Filter data to be saved
      if(classObjectElement.updateProperties.length === 0){
        resolve(classObjectElement);
        return;
      }
      var data = classObjectElement.filterProperties(classObjectElement, classObjectElement.updateProperties);
      if(properties.length){
        data = classObjectElement.filterProperties(data, properties);
      }
      try{
        var response = await fetch(classObjectElement.links.self, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': token,
          },
          body: JSON.stringify(data),
        });
        var resData = await response.json();
        API_Object.checkError(resData);
        classObjectElement.parseData(resData.data);
        // Update data if something changed
        if(classObjectElement.constructor.indexDBInfo().table){
          await classObjectElement.updateInIndexDB(token);
        }
        resolve(classObjectElement);
      }catch(err){
        reject(err);
      }
    });
  }

  async delete(token){
    var classObjectElement = this;
    if(this.constructor !== this.constructor.thisObject().element){
      console.log('The "update()" function can only be called from ' +
        this.constructor.thisObject().element.getClass());
      throw Error('Wrong update function call object.');
    }
    if(this.links.self){
      // Object exists in Server
      // Mark Object as deleted in IndexDB
      if(classObjectElement.constructor.indexDBInfo().table){
        await API_Object.markAsDeletedInIndexDB(classObjectElement);
      }

      // Try to remove Object from Server
      return new Promise(async function(resolve, reject) {
        try{
          var response = await fetch(classObjectElement.links.self, {
            method: 'DELETE',
            headers: {
              'Authorization': token,
            },
          });
          var resData = await response.json();
          API_Object.checkError(resData);
          //Object removed from Server, now delete in IndexDB
          if(classObjectElement.constructor.indexDBInfo().table){
            API_Object.removeFromIndexDB(classObjectElement);
          }
          resolve(classObjectElement);
        }catch(err){
          reject(err);
        }
      });
    }else{
      // Object does not yet exist in Server
      // Delete Object from IndexDB
      if(classObjectElement.constructor.indexDBInfo().table){
        //console.log('Deleted from IndexDB (local Only)');
        API_Object.removeFromIndexDB(classObjectElement);
      }
    }
  }

  filterProperties(data, filterlist){
    var newObject = {};
    for(var tag in filterlist){
      newObject[filterlist[tag]] = data[filterlist[tag]];
    }
    // remove values that are null form object
    for(var propName in data) {
      if (data[propName] === null || data[propName] === undefined) {
        delete newObject[propName];
      }
    }
    return newObject;
  }

  // Update Object(s) in IndexDB
  updateInIndexDB(token, markAsUpdated = false){
    var dataToStore = this.indexDBData();
    var dbinfo = this.constructor.indexDBInfo();
    if(!dbinfo || dbinfo.table === ''){
      throw Error('IndexDB: Table is not defined.');
    }
    var classObject = this;// List or Element

    return new Promise(async function(resolve, reject) {
      var db = await indexDB;
      var transaction = db.transaction([dbinfo.table], 'readwrite');
      transaction.oncomplete = function(event) {
        //console.log(dbinfo.table + ' All done');
      };
      transaction.onerror = function(event) {
        reject(event);
      };
      var objectStore = transaction.objectStore(dbinfo.table);
      // update all Object that need updating (all in list of only one item)
      await Promise.all(dataToStore.map(async function(object) {
        //check for updated objects
        var storedObject = null;
        if(object.key){
          storedObject = await API_Object.getIDBObjectFromRequest(objectStore.get(object.key));
        }
        // Check if Object has been updated when offline
        if(storedObject && API_Object.checkIfUpdated(storedObject,object)){
          //console.log('Element Has Changed sinds last server access');
          // Sent update to Server
          var classNameElement = classObject.constructor.thisObject().element;
          var objectToSave = new classNameElement(storedObject);
          // Update Object on Server (and IndexDB)
          await objectToSave.update(token,[],false);
          // Update given data (upstream)
          var newDataForObject = objectToSave.indexDBData()[0];
          delete newDataForObject.key;
          if(classObject.constructor === classObject.constructor.thisObject().element){
            // Given Element
            classObject.parseData(newDataForObject);
          }else if(classObject.constructor === classObject.constructor.thisObject().list){
            // Given List
            var element = classObject.get('key',object.key);
            element.parseData(newDataForObject);
          }
        }else{
          // Object has not changed.
          delete object.key;
          if(markAsUpdated){
            object.markedAsUpdated = true;
          }
          await API_Object.getIDBObjectFromRequest(objectStore.put(object));
        }
      }));
      //console.log('All Updated in '+dbinfo.table);
      resolve();
    });
  }

  static async getIDBObjectFromRequest(request){
    return new Promise(async function(resolve, reject) {
      request.onsuccess = function(event) {
        resolve(request.result);
      };
      request.onerror = function(event) {
        reject(event);
      };
    });
  }

  static checkIfUpdated(storedObject, currentObject){
    if(!storedObject.lastChangedDate || !currentObject.lastChangedDate){
      return false;
    }
    var storedDate = new Date(storedObject.lastChangedDate);
    var currentDate = new Date(currentObject.lastChangedDate);
    if(storedDate > currentDate){
      // stored object is newer
      // trigger update
      return true;
    }
    return false;
  }

  static async getAllFromIndexDBData(){
    var dbinfo = this.indexDBInfo();
    return new Promise(async function(resolve, reject) {
      var db = await indexDB;
      //console.log('load stored List');

      var transaction = db.transaction([dbinfo.table]);
      var objectStore = transaction.objectStore(dbinfo.table);
      var request = objectStore.getAll();
      request.onerror = function(event) {
        console.log('DB error');
        reject(event);
      };
      request.onsuccess = function(event) {
        resolve(request.result);
      };
    });
  }

  static async getFromIndexDBData(key){
    var dbinfo = this.indexDBInfo();
    return new Promise(async function(resolve, reject) {
      var db = await indexDB;
      //console.log('load stored Element');
      try{
        var transaction = db.transaction([dbinfo.table]);
        var objectStore = transaction.objectStore(dbinfo.table);
        var request = objectStore.get(key);
        request.onerror = function(event) {
          console.log('DB error');
          reject(event);
        };
        request.onsuccess = function(event) {
          resolve(request.result);
        };
      }catch(err){
        console.error('IndexDB Error: ' + err);
        console.error('This could be because object is opened but not loaded via loadedModules.js, table name: ' + dbinfo.table );
      }
    });
  }

  static async removeFromIndexDB(object){
    var dbinfo = object.constructor.indexDBInfo();
    return new Promise(async function(resolve, reject) {
      var db = await indexDB;
      //console.log('remove stored Element');

      var transaction = db.transaction([dbinfo.table], 'readwrite');
      var objectStore = transaction.objectStore(dbinfo.table);
      var request = objectStore.delete(object.key);
      request.onerror = function(event) {
        console.log('DB error');
        reject(event);
      };
      request.onsuccess = function(event) {
        object.key = null;
        object.links.self = null;
        resolve(request.result);
      };
    });
  }

  static async markAsDeletedInIndexDB(object){
    var dbinfo = object.constructor.indexDBInfo();
    return new Promise(async function(resolve, reject) {
      var db = await indexDB;
      //console.log('mark as Deleted');

      var transaction = db.transaction([dbinfo.table], 'readwrite');
      var objectStore = transaction.objectStore(dbinfo.table);
      var dataToStore = object.indexDBData()[0];
      dataToStore.markedAsDeleted = true;
      delete dataToStore.key;
      var request = objectStore.put(dataToStore);
      request.onerror = function(event) {
        console.log('DB error');
        reject(event);
      };
      request.onsuccess = function(event) {
        resolve(request.result);
      };
    });
  }

  static async clearIndexDB(){
    var dbinfo = this.indexDBInfo();
    return new Promise(async function(resolve, reject) {
      indexDB = await indexDB;
      indexDB.transaction([dbinfo.table], 'readwrite')
        .objectStore(dbinfo.table).clear();
      resolve();
    });
  }

  static clearAllInIndexDB(){
    IndexDB.clearIndexedDBInstance();
  }

  toString(){
    return this.type + ': ' + this.key;
  }
  toJSON(){
    return this;
  }

}
