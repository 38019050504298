// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Material Design

// Other Components
import NoteCard from '../NoteCard';
import TaskCard from '../TaskCard';
import ProjectCard from '../ProjectCard';
import Showdown from 'showdown';

// Hepheastus
import {Task} from '../../modules/Tasks';
import {Note} from '../../modules/Notes';
import {Project} from '../../modules/Projects';
import DataContainer from '../../modules/DataContainer';


export default class StarredCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      list: this.dataContainer.getAll('Starred'),
      redirect: null,
    };
    // SECURITY: Check settings
    this.markdownConverter = new Showdown.Converter({
      noHeaderId: true,
      headerLevelStart: 2,
      simplifiedAutoLink: true,
      strikethrough: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true,
      backslashEscapesHTMLTags: true,
    });
  }

  addIncludedElements(){
    this.state.list.items.map(element =>{
      if(!element.includedElement && element.nodeUUID){
        var node = this.dataContainer.get('Nodes','nodeUUID',element.nodeUUID);
        if(node){
          element.includedElement = this.dataContainer.get(node.nodeType,'nodeUUID',element.nodeUUID);
        }
      }
      return element;
    });
  }

  openNode(parrentUUID){
    if(parrentUUID !== null){
      this.setState({redirect: '/nodes/'+parrentUUID});
    }else{
      this.setState({redirect: '/nodes'});
    }
  }

  render(){
    if (this.state.redirect) {
      this.setState({redirect: null});
      return <Redirect to={this.state.redirect} />;
    }
    this.addIncludedElements();
    return (
      <div>
        <p>Starred</p>
        {this.state.list.items.length===0?(
          <p>No Starred items</p>
        ):(
          <div className='cardCollection'>
            {this.state.list.items.map(element =>{
              if(element.includedElement instanceof Task){
                return (
                  <TaskCard
                    key={element.nodeUUID}
                    task={element.includedElement}
                    dataContainer={this.dataContainer}
                    setParrentUUID={this.openNode.bind(this)}
                  />
                );
              }else if(element.includedElement instanceof Note){
                return (
                  <NoteCard
                    key={element.nodeUUID}
                    note={element.includedElement}
                    dataContainer={this.dataContainer}
                    markdownConverter={this.markdownConverter}
                    setParrentUUID={this.openNode.bind(this)}
                  />
                );
              }else if(element.includedElement instanceof Project){
                return (
                  <ProjectCard
                    key={element.nodeUUID}
                    project={element.includedElement}
                    dataContainer={this.dataContainer}
                    setParrentUUID={this.openNode.bind(this)}
                  />
                );
              }else{
                return (<p key={element.nodeUUID}>Element not Implemented: {element.nodeUUID}</p>);
              }
            })}
          </div>
        )}
      </div>
    );
  }
}

StarredCards.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),

};
