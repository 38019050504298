
import Node from './Node';
import Nodes from './Nodes';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: Node,
      list: Nodes,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'Nodes',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/nodes`,
        getLink:    `https://${serverConfig.blldomain}/api/nodes/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/nodes`,
      },
    };
  }

}
