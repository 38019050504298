
import Project from './Project';
import Projects from './Projects';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: Project,
      list: Projects,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'Projects',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/projects`,
        getLink:    `https://${serverConfig.blldomain}/api/projects/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/projects`,
      },
    };
  }

}
