
import StarredItem from './StarredItem';
import Starred from './Starred';
import serverConfig from '../../config';
if(!serverConfig){
  throw new Error('No config (config.js) file found.');
}

export default class ModuleProperties{

  static getObjects(){
    return {
      element: StarredItem,
      list: Starred,
    };
  }

  static getInfo(){
    return {
      indexDBInfo: {
        table: 'Starred',
      },
      APIInfo: {
        listLink:   `https://${serverConfig.blldomain}/api/starred`,
        getLink:    `https://${serverConfig.blldomain}/api/starred/:id`,
        insertLink: `https://${serverConfig.blldomain}/api/starred`,
      },
    };
  }

}
