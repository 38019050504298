// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Material Design

// Other Components
import TaskCard from '../TaskCard';
import ProjectCard from '../ProjectCard';
import Showdown from 'showdown';

// Hepheastus
import DataContainer from '../../modules/DataContainer';


export default class DueCards extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    var startRange = new Date();
    startRange.setDate(startRange.getDate() - 5);
    this.startRange = startRange.toISOString();// Now min 1 day
    var endRange = new Date();
    endRange.setDate(endRange.getDate() + 14);
    this.endRange = endRange.toISOString();// Now plus 2 weeks

    this.state = {
      lists: {
        tasks: this.dataContainer.getAll('Tasks','dueDate',[this.startRange, this.endRange]).sort('dueDate'),
        projects: this.dataContainer.getAll('Projects','dueDate',[this.startRange, this.endRange]).sort('dueDate'),
      },
    };
    // SECURITY: Check settings
    this.markdownConverter = new Showdown.Converter({
      noHeaderId: true,
      headerLevelStart: 2,
      simplifiedAutoLink: true,
      strikethrough: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true,
      backslashEscapesHTMLTags: true,
    });
    this.dataChangeListener = this.dataChange.bind(this);
  }

  dataChange(e){
    if(['Tasks','Projects'].includes(e.detail.datatype)){
      this.setState({
        lists: {
          tasks: this.dataContainer.getAll('Tasks','dueDate',[this.startRange, this.endRange]).sort('dueDate'),
          projects: this.dataContainer.getAll('Projects','dueDate',[this.startRange, this.endRange]).sort('dueDate'),
        },
      });
    }
  }

  componentDidMount(){
    window.addEventListener('dataContainerChange', this.dataChangeListener);
  }

  componentWillUnmount(){
    window.removeEventListener('dataContainerChange', this.dataChangeListener);
  }

  openNode(parrentUUID){
    if(parrentUUID !== null){
      this.setState({redirect: '/nodes/'+parrentUUID});
    }else{
      this.setState({redirect: '/nodes'});
    }
  }

  render(){
    if (this.state.redirect) {
      this.setState({redirect: null});
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <h3>Projects</h3>
        <div className='cardCollection'>
          {this.state.lists.projects.getAll('active', 1).items.map(element =>{
            return (
              <ProjectCard
                key={element.nodeUUID}
                project={element}
                dataContainer={this.dataContainer}
                setParrentUUID={this.openNode.bind(this)}
              />
            );
          })}
        </div>
        <h3>Tasks</h3>
        <div className='cardCollection'>
          {this.state.lists.tasks.sort('status').items.map(element =>{
            return (
              <TaskCard
                key={element.nodeUUID}
                task={element}
                dataContainer={this.dataContainer}
                setParrentUUID={this.openNode.bind(this)}
              />
            );
          })}
        </div>
        {this.state.parrentUUID!==null && (<div>
          <h3>Projects (inactive)</h3>
          <div className='cardCollection'>
            {this.state.lists.projects.getAll('active', 0).items.map(element =>{
              return (
                <ProjectCard
                  key={element.nodeUUID}
                  project={element}
                  dataContainer={this.dataContainer}
                  setParrentUUID={this.openNode.bind(this)}
                />
              );
            })}
          </div>
        </div>)}
      </div>
    );
  }
}


DueCards.propTypes = {
  dataContainer: PropTypes.instanceOf(DataContainer),
};
