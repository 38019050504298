import API_Object from './API_Object';
import UUID from '../identifiers';

export default class API_Element_Object extends API_Object {

  constructor(data = null){
    super(data);
    this.type = 'API_Element_Object';
  }

  fillDefault(){
    super.fillDefault();
    this.key = UUID.uuid();
  }

  parseData(data){
    super.parseData(data);
    this.key = data[this.keyPropertie];
  }

  indexDBData(){
    var data = {};
    for(var i in this.storeProperties){
      data[this.storeProperties[i]] = this[this.storeProperties[i]];
    }
    data.key = this.key;
    return [data];
  }

}
