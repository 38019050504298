// React
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Material Design
import List from '@material/react-list';
import Fab from '@material/react-fab';
import MaterialIcon from '@material/react-material-icon';

// Other Components
import TaskListItem from '../TaskListItem';
import TaskEditDialog from '../TaskEditDialog';
import NodeBreadcrumbs from '../NodeBreadcrumbs';
import Dialogbox from '../Dialogbox';
import Snackbarbox from '../Snackbarbox';

// Hepheastus
import {Task} from '../../modules/Tasks';
import DataContainer from '../../modules/DataContainer';

class TaskList extends Component {

  constructor(props){
    super(props);
    this.dataContainer = props.dataContainer;
    this.state = {
      tasklist: this.dataContainer.getAll('Tasks'),
      parrentUUID: props.parrentUUID,
    };
  }

  componentDidMount(){
    Snackbarbox.setFAB(true);
  }

  componentWillUnmount(){
    Snackbarbox.setFAB(false);
  }

  openAddTaskDialog(){
    var task = new Task();
    task.parrentUUID = this.state.parrentUUID;
    var taskdialog = <TaskEditDialog task={task} dataContainer={this.dataContainer}/>;
    ReactDOM.render(taskdialog, Dialogbox.getRef().current);
  }

  setParrentUUID(parrentUUID){
    this.setState({parrentUUID: parrentUUID});
  }

  render(){
    var tasklist = this.state.tasklist.getAll('parrentUUID', this.state.parrentUUID);
    return (
      <div>
        <p>Tasks</p>
        <NodeBreadcrumbs
          setParrentUUID={this.setParrentUUID.bind(this)}
          currentNodeUUID={this.state.parrentUUID}
          dataContainer={this.dataContainer}
        />
        {tasklist.items.length===0?(
          <p>No Tasks to show</p>
        ):(
          <List twoLine dense checkboxList selectedIndex={[]}>
            {tasklist.items.map(task =>(
              <TaskListItem
                task={task}
                key={task.nodeUUID}
                dataContainer={this.dataContainer}
                setParrentUUID={this.setParrentUUID.bind(this)}
              />
            ))}
          </List>
        )}

        <Fab
          className='app-fab--absolute'
          icon={<MaterialIcon icon="add"/>}
          onClick={() => this.openAddTaskDialog()}
        />
      </div>
    );
  }
}

TaskList.propTypes = {
  parrentUUID: PropTypes.string,
  dataContainer: PropTypes.instanceOf(DataContainer),
};

export default TaskList;
