// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material Design

// Hepheastus
import DataContainer from '../../modules/DataContainer';


export default class NodeBreadcrumbs extends Component {

  constructor(props){
    super(props);
    this.setParrentUUID = props.setParrentUUID;
    this.dataContainer = props.dataContainer;
  }

  getElementByNodeUUID(nodeUUID){
    this.node = this.dataContainer.get('Nodes', 'nodeUUID', nodeUUID);
    if(!this.node){
      return null;
    }
    var element = this.dataContainer.get(this.node.nodeType, 'nodeUUID', this.node.nodeUUID);
    return element;
  }

  render(){
    var crumbs = [];
    var posUUID = this.props.currentNodeUUID;
    while(posUUID != null && crumbs.length < 15){
      var currentElement = this.getElementByNodeUUID(posUUID);
      if(!currentElement){
        break;
      }
      crumbs.push({
        name: currentElement.name?currentElement.name:currentElement.message.substring(0, 10),
        uuid: currentElement.nodeUUID,
      });
      posUUID = currentElement.parrentUUID;
    }
    // flip the list to correct order
    crumbs = crumbs.reverse();

    return (
      <div className='breadcrumbs'>
        <ol>
          <li onClick={()=>{this.setParrentUUID(null);}}>Root</li>
          {crumbs.map(item =>(
            <li
              key={'crumb-'+item.uuid}
              onClick={()=>{this.setParrentUUID(item.uuid);}}
            >
              {item.name}
            </li>
          ))}
        </ol>
      </div>
    );
  }
}

NodeBreadcrumbs.propTypes = {
  currentNodeUUID: PropTypes.string,
  dataContainer: PropTypes.instanceOf(DataContainer),
  setParrentUUID: PropTypes.func,
};
